import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useMutation, useQuery, useInfiniteQuery, UseMutationOptions, UseQueryOptions, UseInfiniteQueryOptions, QueryFunctionContext } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(client: GraphQLClient, query: string, variables?: TVariables, headers?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request<TData, TVariables>(query, variables, headers);
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A time string with format HH:mm:ss.SSS */
  Time: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Article = {
  __typename?: 'Article';
  comments?: Maybe<CommentRelationResponseCollection>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  homeBoard?: Maybe<HomeBoardEntityResponse>;
  title?: Maybe<Scalars['String']>;
  type: Enum_Article_Type;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writer?: Maybe<UsersPermissionsUserEntityResponse>;
};


export type ArticleCommentsArgs = {
  filters?: InputMaybe<CommentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ArticleEntity = {
  __typename?: 'ArticleEntity';
  attributes?: Maybe<Article>;
  /** 해당 게시글에 달린 댓글의 개수 */
  commentCount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
};

export type ArticleEntityResponse = {
  __typename?: 'ArticleEntityResponse';
  data?: Maybe<ArticleEntity>;
};

export type ArticleEntityResponseCollection = {
  __typename?: 'ArticleEntityResponseCollection';
  data: Array<ArticleEntity>;
  meta: ResponseCollectionMeta;
};

export type ArticleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ArticleFiltersInput>>>;
  comments?: InputMaybe<CommentFiltersInput>;
  content?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  homeBoard?: InputMaybe<HomeBoardFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<ArticleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ArticleFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  writer?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type ArticleInput = {
  comments?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  content?: InputMaybe<Scalars['String']>;
  homeBoard?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Enum_Article_Type>;
  writer?: InputMaybe<Scalars['ID']>;
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  contains?: InputMaybe<Scalars['Boolean']>;
  containsi?: InputMaybe<Scalars['Boolean']>;
  endsWith?: InputMaybe<Scalars['Boolean']>;
  eq?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Boolean']>;
  gte?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  lt?: InputMaybe<Scalars['Boolean']>;
  lte?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']>;
  notContainsi?: InputMaybe<Scalars['Boolean']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']>;
};

export type Branch = {
  __typename?: 'Branch';
  businessHours?: Maybe<BusinessHourRelationResponseCollection>;
  color: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  exceptionalCloseDates?: Maybe<ExceptionalCloseDateRelationResponseCollection>;
  name: Scalars['String'];
  priority: Scalars['Int'];
  productCategories?: Maybe<ProductCategoryRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};


export type BranchBusinessHoursArgs = {
  filters?: InputMaybe<BusinessHourFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BranchExceptionalCloseDatesArgs = {
  filters?: InputMaybe<ExceptionalCloseDateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type BranchProductCategoriesArgs = {
  filters?: InputMaybe<ProductCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BranchEntity = {
  __typename?: 'BranchEntity';
  attributes?: Maybe<Branch>;
  id?: Maybe<Scalars['ID']>;
  /** 지점이 열려있는가? */
  isOpen?: Maybe<Scalars['Boolean']>;
};

export type BranchEntityResponse = {
  __typename?: 'BranchEntityResponse';
  data?: Maybe<BranchEntity>;
};

export type BranchFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<BranchFiltersInput>>>;
  businessHours?: InputMaybe<BusinessHourFiltersInput>;
  color?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  exceptionalCloseDates?: InputMaybe<ExceptionalCloseDateFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<BranchFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<BranchFiltersInput>>>;
  priority?: InputMaybe<IntFilterInput>;
  productCategories?: InputMaybe<ProductCategoryFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
};

export type BranchInput = {
  businessHours?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  color?: InputMaybe<Scalars['String']>;
  exceptionalCloseDates?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  productCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  url?: InputMaybe<Scalars['String']>;
};

export type BulkMadeOrderInputType = {
  /** 주문이 데이터가 담겨있는 배열 */
  orders?: InputMaybe<Array<InputMaybe<MadeOrderInputType>>>;
};

export type BusinessHour = {
  __typename?: 'BusinessHour';
  branch?: Maybe<BranchEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  endAt: Scalars['Time'];
  startAt: Scalars['Time'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BusinessHourEntity = {
  __typename?: 'BusinessHourEntity';
  attributes?: Maybe<BusinessHour>;
  id?: Maybe<Scalars['ID']>;
};

export type BusinessHourEntityResponse = {
  __typename?: 'BusinessHourEntityResponse';
  data?: Maybe<BusinessHourEntity>;
};

export type BusinessHourEntityResponseCollection = {
  __typename?: 'BusinessHourEntityResponseCollection';
  data: Array<BusinessHourEntity>;
  meta: ResponseCollectionMeta;
};

export type BusinessHourFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<BusinessHourFiltersInput>>>;
  branch?: InputMaybe<BranchFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  endAt?: InputMaybe<TimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<BusinessHourFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<BusinessHourFiltersInput>>>;
  startAt?: InputMaybe<TimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type BusinessHourInput = {
  branch?: InputMaybe<Scalars['ID']>;
  endAt?: InputMaybe<Scalars['Time']>;
  startAt?: InputMaybe<Scalars['Time']>;
};

export type BusinessHourRelationResponseCollection = {
  __typename?: 'BusinessHourRelationResponseCollection';
  data: Array<BusinessHourEntity>;
};

export type CategoryPrioritySwapType = {
  __typename?: 'CategoryPrioritySwapType';
  /** message */
  message: Scalars['String'];
  /** Request is success */
  ok: Scalars['Boolean'];
};

export type Comment = {
  __typename?: 'Comment';
  article?: Maybe<ArticleEntityResponse>;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  writer?: Maybe<UsersPermissionsUserEntityResponse>;
};

export type CommentEntity = {
  __typename?: 'CommentEntity';
  attributes?: Maybe<Comment>;
  id?: Maybe<Scalars['ID']>;
};

export type CommentEntityResponse = {
  __typename?: 'CommentEntityResponse';
  data?: Maybe<CommentEntity>;
};

export type CommentEntityResponseCollection = {
  __typename?: 'CommentEntityResponseCollection';
  data: Array<CommentEntity>;
  meta: ResponseCollectionMeta;
};

export type CommentFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CommentFiltersInput>>>;
  article?: InputMaybe<ArticleFiltersInput>;
  content?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<CommentFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CommentFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  writer?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type CommentInput = {
  article?: InputMaybe<Scalars['ID']>;
  content?: InputMaybe<Scalars['String']>;
  writer?: InputMaybe<Scalars['ID']>;
};

export type CommentRelationResponseCollection = {
  __typename?: 'CommentRelationResponseCollection';
  data: Array<CommentEntity>;
};

export type Coupon = {
  __typename?: 'Coupon';
  couponTemplate?: Maybe<CouponTemplateEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  creator: Scalars['String'];
  owner?: Maybe<UsersPermissionsUserEntityResponse>;
  stamp?: Maybe<StampEntityResponse>;
  state: Enum_Coupon_State;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  usedOrder?: Maybe<OrderEntityResponse>;
};

export type CouponEntity = {
  __typename?: 'CouponEntity';
  attributes?: Maybe<Coupon>;
  id?: Maybe<Scalars['ID']>;
};

export type CouponEntityResponse = {
  __typename?: 'CouponEntityResponse';
  data?: Maybe<CouponEntity>;
};

export type CouponEntityResponseCollection = {
  __typename?: 'CouponEntityResponseCollection';
  data: Array<CouponEntity>;
  meta: ResponseCollectionMeta;
};

export type CouponFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CouponFiltersInput>>>;
  couponTemplate?: InputMaybe<CouponTemplateFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  creator?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<CouponFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CouponFiltersInput>>>;
  owner?: InputMaybe<UsersPermissionsUserFiltersInput>;
  stamp?: InputMaybe<StampFiltersInput>;
  state?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  usedAt?: InputMaybe<DateTimeFilterInput>;
  usedOrder?: InputMaybe<OrderFiltersInput>;
};

export type CouponInput = {
  couponTemplate?: InputMaybe<Scalars['ID']>;
  creator?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['ID']>;
  stamp?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Enum_Coupon_State>;
  usedAt?: InputMaybe<Scalars['DateTime']>;
  usedOrder?: InputMaybe<Scalars['ID']>;
};

export type CouponRelationResponseCollection = {
  __typename?: 'CouponRelationResponseCollection';
  data: Array<CouponEntity>;
};

export type CouponTemplate = {
  __typename?: 'CouponTemplate';
  availableCategories?: Maybe<ProductCategoryRelationResponseCollection>;
  coupons?: Maybe<CouponRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  isPermanent: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CouponTemplateAvailableCategoriesArgs = {
  filters?: InputMaybe<ProductCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CouponTemplateCouponsArgs = {
  filters?: InputMaybe<CouponFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CouponTemplateEntity = {
  __typename?: 'CouponTemplateEntity';
  attributes?: Maybe<CouponTemplate>;
  id?: Maybe<Scalars['ID']>;
};

export type CouponTemplateEntityResponse = {
  __typename?: 'CouponTemplateEntityResponse';
  data?: Maybe<CouponTemplateEntity>;
};

export type CouponTemplateEntityResponseCollection = {
  __typename?: 'CouponTemplateEntityResponseCollection';
  data: Array<CouponTemplateEntity>;
  meta: ResponseCollectionMeta;
};

export type CouponTemplateFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CouponTemplateFiltersInput>>>;
  availableCategories?: InputMaybe<ProductCategoryFiltersInput>;
  coupons?: InputMaybe<CouponFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isPermanent?: InputMaybe<BooleanFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CouponTemplateFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CouponTemplateFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CouponTemplateInput = {
  availableCategories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  coupons?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  isPermanent?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateGiftInputType = {
  /** 선물 카드 ID */
  giftCard?: InputMaybe<Scalars['ID']>;
  /** 보내는 메세지 */
  message?: InputMaybe<Scalars['String']>;
  /** 선물을 받은 사람 ID */
  receiver: Scalars['Int'];
  /** 선물을 보낸 사람 ID */
  sender?: InputMaybe<Scalars['Int']>;
  /** 선물의 상태 */
  state?: InputMaybe<GiftState>;
  /** 선물에 사용된 주문 ID */
  usedOrder?: InputMaybe<Scalars['Int']>;
};

export type CustomBranchEntityResponse = {
  __typename?: 'CustomBranchEntityResponse';
  /** BranchEntity */
  data: BranchEntity;
};

export type CustomBranchEntityResponseCollection = {
  __typename?: 'CustomBranchEntityResponseCollection';
  /** BranchEntity */
  data: Array<BranchEntity>;
  /** Collection Meta */
  meta: ResponseCollectionMeta;
};

export type CustomProductEntityResponse = {
  __typename?: 'CustomProductEntityResponse';
  /** ProductEntity */
  data: ProductEntity;
};

export type DateFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  contains?: InputMaybe<Scalars['Date']>;
  containsi?: InputMaybe<Scalars['Date']>;
  endsWith?: InputMaybe<Scalars['Date']>;
  eq?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  ne?: InputMaybe<Scalars['Date']>;
  not?: InputMaybe<DateFilterInput>;
  notContains?: InputMaybe<Scalars['Date']>;
  notContainsi?: InputMaybe<Scalars['Date']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  startsWith?: InputMaybe<Scalars['Date']>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  contains?: InputMaybe<Scalars['DateTime']>;
  containsi?: InputMaybe<Scalars['DateTime']>;
  endsWith?: InputMaybe<Scalars['DateTime']>;
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  ne?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']>;
  notContainsi?: InputMaybe<Scalars['DateTime']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']>;
};

export enum Enum_Article_Type {
  Article = 'article',
  News = 'news',
  Notice = 'notice'
}

export enum Enum_Coupon_State {
  UnUsed = 'unUsed',
  Used = 'used'
}

export enum Enum_Giftcard_Type {
  Cheer = 'cheer',
  Congrats = 'congrats',
  Love = 'love',
  Thanks = 'thanks'
}

export enum Enum_Gift_State {
  UnUsed = 'unUsed',
  Used = 'used'
}

export enum Enum_Order_State {
  Canceled = 'canceled',
  Done = 'done',
  Gifted = 'gifted',
  InProgress = 'inProgress',
  Ordered = 'ordered'
}

export enum Enum_Productoptionbundle_Type {
  Button = 'button',
  Select = 'select'
}

export enum Enum_Product_State {
  NotSale = 'notSale',
  Sale = 'sale'
}

export enum Enum_Smsalert_Trigger {
  BirthdayCouponRecieved = 'birthdayCouponRecieved',
  GiftRecieved = 'giftRecieved',
  OrderCancled = 'orderCancled',
  OrderDone = 'orderDone',
  OrderSuccess = 'orderSuccess'
}

export enum Enum_Stamp_State {
  UnUsed = 'unUsed',
  Used = 'used'
}

export type ExceptionalCloseDate = {
  __typename?: 'ExceptionalCloseDate';
  branch?: Maybe<BranchEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date: Scalars['Date'];
  endAt: Scalars['Time'];
  startAt: Scalars['Time'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExceptionalCloseDateEntity = {
  __typename?: 'ExceptionalCloseDateEntity';
  attributes?: Maybe<ExceptionalCloseDate>;
  id?: Maybe<Scalars['ID']>;
};

export type ExceptionalCloseDateEntityResponse = {
  __typename?: 'ExceptionalCloseDateEntityResponse';
  data?: Maybe<ExceptionalCloseDateEntity>;
};

export type ExceptionalCloseDateEntityResponseCollection = {
  __typename?: 'ExceptionalCloseDateEntityResponseCollection';
  data: Array<ExceptionalCloseDateEntity>;
  meta: ResponseCollectionMeta;
};

export type ExceptionalCloseDateFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ExceptionalCloseDateFiltersInput>>>;
  branch?: InputMaybe<BranchFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  date?: InputMaybe<DateFilterInput>;
  endAt?: InputMaybe<TimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<ExceptionalCloseDateFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ExceptionalCloseDateFiltersInput>>>;
  startAt?: InputMaybe<TimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ExceptionalCloseDateInput = {
  branch?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
  endAt?: InputMaybe<Scalars['Time']>;
  startAt?: InputMaybe<Scalars['Time']>;
};

export type ExceptionalCloseDateRelationResponseCollection = {
  __typename?: 'ExceptionalCloseDateRelationResponseCollection';
  data: Array<ExceptionalCloseDateEntity>;
};

export type Favorite = {
  __typename?: 'Favorite';
  createdAt?: Maybe<Scalars['DateTime']>;
  product?: Maybe<ProductEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};

export type FavoriteEntity = {
  __typename?: 'FavoriteEntity';
  attributes?: Maybe<Favorite>;
  id?: Maybe<Scalars['ID']>;
};

export type FavoriteEntityResponse = {
  __typename?: 'FavoriteEntityResponse';
  data?: Maybe<FavoriteEntity>;
};

export type FavoriteEntityResponseCollection = {
  __typename?: 'FavoriteEntityResponseCollection';
  data: Array<FavoriteEntity>;
  meta: ResponseCollectionMeta;
};

export type FavoriteFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<FavoriteFiltersInput>>>;
  /** 조회를 수행 할 지점 */
  branch?: InputMaybe<Scalars['ID']>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<FavoriteFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<FavoriteFiltersInput>>>;
  product?: InputMaybe<ProductFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type FavoriteInput = {
  product?: InputMaybe<Scalars['ID']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  contains?: InputMaybe<Scalars['Float']>;
  containsi?: InputMaybe<Scalars['Float']>;
  endsWith?: InputMaybe<Scalars['Float']>;
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']>;
  notContainsi?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  startsWith?: InputMaybe<Scalars['Float']>;
};

export type GenericMorph = Article | Branch | BusinessHour | Comment | Coupon | CouponTemplate | ExceptionalCloseDate | Favorite | Gift | GiftCard | HomeBoard | I18NLocale | Order | Product | ProductCategory | ProductOption | ProductOptionBundle | SmsAlert | Stamp | UploadFile | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser;

export type Gift = {
  __typename?: 'Gift';
  createdAt?: Maybe<Scalars['DateTime']>;
  giftCard?: Maybe<GiftCardEntityResponse>;
  message: Scalars['String'];
  name: Scalars['String'];
  originBranch?: Maybe<BranchEntityResponse>;
  price: Scalars['Int'];
  receiver?: Maybe<UsersPermissionsUserEntityResponse>;
  sender?: Maybe<UsersPermissionsUserEntityResponse>;
  state: Enum_Gift_State;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedAt?: Maybe<Scalars['DateTime']>;
  usedOrder?: Maybe<OrderEntityResponse>;
};

export type GiftCard = {
  __typename?: 'GiftCard';
  createdAt?: Maybe<Scalars['DateTime']>;
  image: UploadFileEntityResponse;
  type: Enum_Giftcard_Type;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type GiftCardEntity = {
  __typename?: 'GiftCardEntity';
  attributes?: Maybe<GiftCard>;
  id?: Maybe<Scalars['ID']>;
};

export type GiftCardEntityResponse = {
  __typename?: 'GiftCardEntityResponse';
  data?: Maybe<GiftCardEntity>;
};

export type GiftCardEntityResponseCollection = {
  __typename?: 'GiftCardEntityResponseCollection';
  data: Array<GiftCardEntity>;
  meta: ResponseCollectionMeta;
};

export type GiftCardFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<GiftCardFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<GiftCardFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<GiftCardFiltersInput>>>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type GiftCardInput = {
  image?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Enum_Giftcard_Type>;
};

export type GiftEntity = {
  __typename?: 'GiftEntity';
  attributes?: Maybe<Gift>;
  id?: Maybe<Scalars['ID']>;
};

export type GiftEntityResponse = {
  __typename?: 'GiftEntityResponse';
  data?: Maybe<GiftEntity>;
};

export type GiftEntityResponseCollection = {
  __typename?: 'GiftEntityResponseCollection';
  data: Array<GiftEntity>;
  meta: ResponseCollectionMeta;
};

export type GiftFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<GiftFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  giftCard?: InputMaybe<GiftCardFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  message?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<GiftFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<GiftFiltersInput>>>;
  originBranch?: InputMaybe<BranchFiltersInput>;
  price?: InputMaybe<IntFilterInput>;
  receiver?: InputMaybe<UsersPermissionsUserFiltersInput>;
  sender?: InputMaybe<UsersPermissionsUserFiltersInput>;
  state?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  usedAt?: InputMaybe<DateTimeFilterInput>;
  usedOrder?: InputMaybe<OrderFiltersInput>;
};

export type GiftInput = {
  giftCard?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  originBranch?: InputMaybe<Scalars['ID']>;
  price?: InputMaybe<Scalars['Int']>;
  receiver?: InputMaybe<Scalars['ID']>;
  sender?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Enum_Gift_State>;
  usedAt?: InputMaybe<Scalars['DateTime']>;
  usedOrder?: InputMaybe<Scalars['ID']>;
};

export type HomeBoard = {
  __typename?: 'HomeBoard';
  createdAt?: Maybe<Scalars['DateTime']>;
  exposedArticle?: Maybe<ArticleEntityResponse>;
  isPublished: Scalars['Boolean'];
  thumbnail?: Maybe<UploadFileEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HomeBoardEntity = {
  __typename?: 'HomeBoardEntity';
  attributes?: Maybe<HomeBoard>;
  id?: Maybe<Scalars['ID']>;
};

export type HomeBoardEntityResponse = {
  __typename?: 'HomeBoardEntityResponse';
  data?: Maybe<HomeBoardEntity>;
};

export type HomeBoardEntityResponseCollection = {
  __typename?: 'HomeBoardEntityResponseCollection';
  data: Array<HomeBoardEntity>;
  meta: ResponseCollectionMeta;
};

export type HomeBoardFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<HomeBoardFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  exposedArticle?: InputMaybe<ArticleFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  isPublished?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<HomeBoardFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<HomeBoardFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type HomeBoardInput = {
  exposedArticle?: InputMaybe<Scalars['ID']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  thumbnail?: InputMaybe<Scalars['ID']>;
};

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  containsi?: InputMaybe<Scalars['ID']>;
  endsWith?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']>;
  notContainsi?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  startsWith?: InputMaybe<Scalars['ID']>;
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contains?: InputMaybe<Scalars['Int']>;
  containsi?: InputMaybe<Scalars['Int']>;
  endsWith?: InputMaybe<Scalars['Int']>;
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']>;
  notContainsi?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startsWith?: InputMaybe<Scalars['Int']>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  contains?: InputMaybe<Scalars['JSON']>;
  containsi?: InputMaybe<Scalars['JSON']>;
  endsWith?: InputMaybe<Scalars['JSON']>;
  eq?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  ne?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']>;
  notContainsi?: InputMaybe<Scalars['JSON']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  startsWith?: InputMaybe<Scalars['JSON']>;
};

export type MadeOrderInputType = {
  /** 주문 지점 ID */
  branch: Scalars['Int'];
  /** 선물로 사용될 때 선물의 데이터 */
  gift?: InputMaybe<CreateGiftInputType>;
  /** 주문자 ID */
  orderer?: InputMaybe<Scalars['Int']>;
  /** 주문한 제품 ID */
  product: Scalars['Int'];
  /** 주문 시 선택한 옵션들의 ID */
  productOptions?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** 수량 */
  quantity: Scalars['Int'];
  /** 요청사항 */
  request?: InputMaybe<Scalars['String']>;
  /** 주문 상태 */
  state: OrderState;
  /** 주문에 사용된 쿠폰의 ID */
  usedCoupon?: InputMaybe<Scalars['Int']>;
  /** 주문에 사용된 선물의 ID */
  usedGift?: InputMaybe<Scalars['Int']>;
};

export type MadeOrderType = {
  __typename?: 'MadeOrderType';
  /** message */
  message: Scalars['String'];
  /** Request is success */
  ok: Scalars['Boolean'];
};

export type MonthlyOrderStatisticsEntity = {
  __typename?: 'MonthlyOrderStatisticsEntity';
  companyTotals?: Maybe<Array<Maybe<StatisticsObject>>>;
  month?: Maybe<Scalars['Date']>;
  totalAmount?: Maybe<Scalars['Int']>;
  totalQuantity?: Maybe<Scalars['Int']>;
};

export type MonthlyOrderStatisticsInputType = {
  /** 조회할 지점 리스트 */
  branch?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** 조회를 마칠 날자 */
  endAt?: InputMaybe<Scalars['Date']>;
  /** 조회를 시작할 날자 */
  startAt?: InputMaybe<Scalars['Date']>;
};

export type MonthlyOrderStatisticsResponseType = {
  __typename?: 'MonthlyOrderStatisticsResponseType';
  orders?: Maybe<Array<Maybe<MonthlyOrderStatisticsEntity>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  branchPrioritySwap?: Maybe<CategoryPrioritySwapType>;
  categoryPrioritySwap?: Maybe<CategoryPrioritySwapType>;
  createArticle?: Maybe<ArticleEntityResponse>;
  createBranch?: Maybe<BranchEntityResponse>;
  createBusinessHour?: Maybe<BusinessHourEntityResponse>;
  createComment?: Maybe<CommentEntityResponse>;
  createCoupon?: Maybe<CouponEntityResponse>;
  createCouponTemplate?: Maybe<CouponTemplateEntityResponse>;
  createExceptionalCloseDate?: Maybe<ExceptionalCloseDateEntityResponse>;
  createFavorite?: Maybe<FavoriteEntityResponse>;
  createGift?: Maybe<GiftEntityResponse>;
  createGiftCard?: Maybe<GiftCardEntityResponse>;
  createHomeBoard?: Maybe<HomeBoardEntityResponse>;
  createOrder?: Maybe<OrderEntityResponse>;
  createProduct?: Maybe<ProductEntityResponse>;
  createProductCategory?: Maybe<ProductCategoryEntityResponse>;
  createProductOption?: Maybe<ProductOptionEntityResponse>;
  createProductOptionBundle?: Maybe<ProductOptionBundleEntityResponse>;
  createSmsAlert?: Maybe<SmsAlertEntityResponse>;
  createStamp?: Maybe<StampEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteArticle?: Maybe<ArticleEntityResponse>;
  deleteBranch?: Maybe<BranchEntityResponse>;
  deleteBusinessHour?: Maybe<BusinessHourEntityResponse>;
  deleteComment?: Maybe<CommentEntityResponse>;
  deleteCoupon?: Maybe<CouponEntityResponse>;
  deleteCouponTemplate?: Maybe<CouponTemplateEntityResponse>;
  deleteExceptionalCloseDate?: Maybe<ExceptionalCloseDateEntityResponse>;
  deleteFavorite?: Maybe<FavoriteEntityResponse>;
  deleteGift?: Maybe<GiftEntityResponse>;
  deleteGiftCard?: Maybe<GiftCardEntityResponse>;
  deleteHomeBoard?: Maybe<HomeBoardEntityResponse>;
  deleteOrder?: Maybe<OrderEntityResponse>;
  deleteProduct?: Maybe<ProductEntityResponse>;
  deleteProductCategory?: Maybe<ProductCategoryEntityResponse>;
  deleteProductOption?: Maybe<ProductOptionEntityResponse>;
  deleteProductOptionBundle?: Maybe<ProductOptionBundleEntityResponse>;
  deleteSmsAlert?: Maybe<SmsAlertEntityResponse>;
  deleteStamp?: Maybe<StampEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  madeOrder?: Maybe<MadeOrderType>;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  productPrioritySwap?: Maybe<CategoryPrioritySwapType>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  updateArticle?: Maybe<ArticleEntityResponse>;
  updateBranch?: Maybe<BranchEntityResponse>;
  updateBusinessHour?: Maybe<BusinessHourEntityResponse>;
  updateComment?: Maybe<CommentEntityResponse>;
  updateCoupon?: Maybe<CouponEntityResponse>;
  updateCouponTemplate?: Maybe<CouponTemplateEntityResponse>;
  updateExceptionalCloseDate?: Maybe<ExceptionalCloseDateEntityResponse>;
  updateFavorite?: Maybe<FavoriteEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateGift?: Maybe<GiftEntityResponse>;
  updateGiftCard?: Maybe<GiftCardEntityResponse>;
  updateHomeBoard?: Maybe<HomeBoardEntityResponse>;
  updateOrder?: Maybe<OrderEntityResponse>;
  updateProduct?: Maybe<ProductEntityResponse>;
  updateProductCategory?: Maybe<ProductCategoryEntityResponse>;
  updateProductOption?: Maybe<ProductOptionEntityResponse>;
  updateProductOptionBundle?: Maybe<ProductOptionBundleEntityResponse>;
  updateSmsAlert?: Maybe<SmsAlertEntityResponse>;
  updateStamp?: Maybe<StampEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  upload: UploadFileEntityResponse;
};


export type MutationBranchPrioritySwapArgs = {
  originPrirority?: InputMaybe<Scalars['Int']>;
  sortingBranchId?: InputMaybe<Scalars['ID']>;
  sortingPriority?: InputMaybe<Scalars['Int']>;
};


export type MutationCategoryPrioritySwapArgs = {
  originId?: InputMaybe<Scalars['ID']>;
  sortingId?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateArticleArgs = {
  data: ArticleInput;
};


export type MutationCreateBranchArgs = {
  data: BranchInput;
};


export type MutationCreateBusinessHourArgs = {
  data: BusinessHourInput;
};


export type MutationCreateCommentArgs = {
  data: CommentInput;
};


export type MutationCreateCouponArgs = {
  data: CouponInput;
};


export type MutationCreateCouponTemplateArgs = {
  data: CouponTemplateInput;
};


export type MutationCreateExceptionalCloseDateArgs = {
  data: ExceptionalCloseDateInput;
};


export type MutationCreateFavoriteArgs = {
  data: FavoriteInput;
};


export type MutationCreateGiftArgs = {
  data: GiftInput;
};


export type MutationCreateGiftCardArgs = {
  data: GiftCardInput;
};


export type MutationCreateHomeBoardArgs = {
  data: HomeBoardInput;
};


export type MutationCreateOrderArgs = {
  data: OrderInput;
};


export type MutationCreateProductArgs = {
  data: ProductInput;
};


export type MutationCreateProductCategoryArgs = {
  data: ProductCategoryInput;
};


export type MutationCreateProductOptionArgs = {
  data: ProductOptionInput;
};


export type MutationCreateProductOptionBundleArgs = {
  data: ProductOptionBundleInput;
};


export type MutationCreateSmsAlertArgs = {
  data: SmsAlertInput;
};


export type MutationCreateStampArgs = {
  data: StampInput;
};


export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationDeleteArticleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBranchArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteBusinessHourArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCouponArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCouponTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteExceptionalCloseDateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteFavoriteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGiftArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGiftCardArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteHomeBoardArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductOptionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteProductOptionBundleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSmsAlertArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteStampArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationMadeOrderArgs = {
  bulkOrderDatas?: InputMaybe<BulkMadeOrderInputType>;
  orderData?: InputMaybe<MadeOrderInputType>;
};


export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  files: Array<InputMaybe<Scalars['Upload']>>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
};


export type MutationProductPrioritySwapArgs = {
  originId?: InputMaybe<Scalars['ID']>;
  sortingId?: InputMaybe<Scalars['ID']>;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};


export type MutationResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationUpdateArticleArgs = {
  data: ArticleInput;
  id: Scalars['ID'];
};


export type MutationUpdateBranchArgs = {
  data: BranchInput;
  id: Scalars['ID'];
};


export type MutationUpdateBusinessHourArgs = {
  data: BusinessHourInput;
  id: Scalars['ID'];
};


export type MutationUpdateCommentArgs = {
  data: CommentInput;
  id: Scalars['ID'];
};


export type MutationUpdateCouponArgs = {
  data: CouponInput;
  id: Scalars['ID'];
};


export type MutationUpdateCouponTemplateArgs = {
  data: CouponTemplateInput;
  id: Scalars['ID'];
};


export type MutationUpdateExceptionalCloseDateArgs = {
  data: ExceptionalCloseDateInput;
  id: Scalars['ID'];
};


export type MutationUpdateFavoriteArgs = {
  data: FavoriteInput;
  id: Scalars['ID'];
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info?: InputMaybe<FileInfoInput>;
};


export type MutationUpdateGiftArgs = {
  data: GiftInput;
  id: Scalars['ID'];
};


export type MutationUpdateGiftCardArgs = {
  data: GiftCardInput;
  id: Scalars['ID'];
};


export type MutationUpdateHomeBoardArgs = {
  data: HomeBoardInput;
  id: Scalars['ID'];
};


export type MutationUpdateOrderArgs = {
  data: OrderInput;
  id: Scalars['ID'];
};


export type MutationUpdateProductArgs = {
  data: ProductInput;
  id: Scalars['ID'];
};


export type MutationUpdateProductCategoryArgs = {
  data: ProductCategoryInput;
  id: Scalars['ID'];
};


export type MutationUpdateProductOptionArgs = {
  data: ProductOptionInput;
  id: Scalars['ID'];
};


export type MutationUpdateProductOptionBundleArgs = {
  data: ProductOptionBundleInput;
  id: Scalars['ID'];
};


export type MutationUpdateSmsAlertArgs = {
  data: SmsAlertInput;
  id: Scalars['ID'];
};


export type MutationUpdateStampArgs = {
  data: StampInput;
  id: Scalars['ID'];
};


export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID'];
};


export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
};

export type Order = {
  __typename?: 'Order';
  amount: Scalars['Int'];
  branch?: Maybe<BranchEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  gift?: Maybe<GiftEntityResponse>;
  orderer?: Maybe<UsersPermissionsUserEntityResponse>;
  product?: Maybe<ProductEntityResponse>;
  productOptions: Scalars['String'];
  quantity: Scalars['Int'];
  request: Scalars['String'];
  state: Enum_Order_State;
  updatedAt?: Maybe<Scalars['DateTime']>;
  usedCoupon?: Maybe<CouponEntityResponse>;
  usedGift?: Maybe<GiftEntityResponse>;
};

export type OrderEntity = {
  __typename?: 'OrderEntity';
  attributes?: Maybe<Order>;
  id?: Maybe<Scalars['ID']>;
};

export type OrderEntityResponse = {
  __typename?: 'OrderEntityResponse';
  data?: Maybe<OrderEntity>;
};

export type OrderEntityResponseCollection = {
  __typename?: 'OrderEntityResponseCollection';
  data: Array<OrderEntity>;
  meta: ResponseCollectionMeta;
};

export type OrderFiltersInput = {
  amount?: InputMaybe<IntFilterInput>;
  and?: InputMaybe<Array<InputMaybe<OrderFiltersInput>>>;
  branch?: InputMaybe<BranchFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  gift?: InputMaybe<GiftFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<OrderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<OrderFiltersInput>>>;
  orderer?: InputMaybe<UsersPermissionsUserFiltersInput>;
  product?: InputMaybe<ProductFiltersInput>;
  productOptions?: InputMaybe<StringFilterInput>;
  quantity?: InputMaybe<IntFilterInput>;
  request?: InputMaybe<StringFilterInput>;
  state?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  usedCoupon?: InputMaybe<CouponFiltersInput>;
  usedGift?: InputMaybe<GiftFiltersInput>;
};

export type OrderInput = {
  amount?: InputMaybe<Scalars['Int']>;
  branch?: InputMaybe<Scalars['ID']>;
  gift?: InputMaybe<Scalars['ID']>;
  orderer?: InputMaybe<Scalars['ID']>;
  product?: InputMaybe<Scalars['ID']>;
  productOptions?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  request?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Enum_Order_State>;
  usedCoupon?: InputMaybe<Scalars['ID']>;
  usedGift?: InputMaybe<Scalars['ID']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
};

export type PeriodOrderStatisticsEntity = {
  __typename?: 'PeriodOrderStatisticsEntity';
  branchName?: Maybe<Scalars['String']>;
  companyTotals?: Maybe<Array<Maybe<StatisticsObject>>>;
  price?: Maybe<Scalars['Int']>;
  productName?: Maybe<Scalars['String']>;
  sale?: Maybe<SaleEntity>;
  totalAmount?: Maybe<Scalars['Int']>;
};

export type PeriodOrderStatisticsInputType = {
  /** 조회할 지점 리스트 */
  branch?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** 조회를 마칠 날자 */
  endAt?: InputMaybe<Scalars['Date']>;
  /** 조회를 시작할 날자 */
  startAt?: InputMaybe<Scalars['Date']>;
};

export type PeriodOrderStatisticsResponseType = {
  __typename?: 'PeriodOrderStatisticsResponseType';
  statistics?: Maybe<Array<Maybe<PeriodOrderStatisticsEntity>>>;
};

export type Product = {
  __typename?: 'Product';
  category?: Maybe<ProductCategoryEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  inventory: Scalars['Int'];
  isInfiniteInventory: Scalars['Boolean'];
  name: Scalars['String'];
  optionBundles?: Maybe<ProductOptionBundleRelationResponseCollection>;
  price: Scalars['Int'];
  priority: Scalars['Int'];
  state: Enum_Product_State;
  thumbnail?: Maybe<UploadFileEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ProductOptionBundlesArgs = {
  filters?: InputMaybe<ProductOptionBundleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  branch?: Maybe<BranchEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultProductOption?: Maybe<ProductOptionRelationResponseCollection>;
  isOrderMade: Scalars['Boolean'];
  isRegularSale: Scalars['Boolean'];
  name: Scalars['String'];
  options?: Maybe<ProductOptionBundleRelationResponseCollection>;
  priority: Scalars['Int'];
  products?: Maybe<ProductRelationResponseCollection>;
  smsTemplate?: Maybe<ArticleEntityResponse>;
  thumbnail: UploadFileEntityResponse;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ProductCategoryDefaultProductOptionArgs = {
  filters?: InputMaybe<ProductOptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ProductCategoryOptionsArgs = {
  filters?: InputMaybe<ProductOptionBundleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type ProductCategoryProductsArgs = {
  filters?: InputMaybe<ProductFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ProductCategoryEntity = {
  __typename?: 'ProductCategoryEntity';
  attributes?: Maybe<ProductCategory>;
  id?: Maybe<Scalars['ID']>;
};

export type ProductCategoryEntityResponse = {
  __typename?: 'ProductCategoryEntityResponse';
  data?: Maybe<ProductCategoryEntity>;
};

export type ProductCategoryEntityResponseCollection = {
  __typename?: 'ProductCategoryEntityResponseCollection';
  data: Array<ProductCategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type ProductCategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ProductCategoryFiltersInput>>>;
  branch?: InputMaybe<BranchFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  defaultProductOption?: InputMaybe<ProductOptionFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  isOrderMade?: InputMaybe<BooleanFilterInput>;
  isRegularSale?: InputMaybe<BooleanFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ProductCategoryFiltersInput>;
  options?: InputMaybe<ProductOptionBundleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ProductCategoryFiltersInput>>>;
  priority?: InputMaybe<IntFilterInput>;
  products?: InputMaybe<ProductFiltersInput>;
  smsTemplate?: InputMaybe<ArticleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ProductCategoryInput = {
  branch?: InputMaybe<Scalars['ID']>;
  defaultProductOption?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  isOrderMade?: InputMaybe<Scalars['Boolean']>;
  isRegularSale?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  priority?: InputMaybe<Scalars['Int']>;
  /** 같이 추가할 옵션 번들의 정보 */
  productOptionBundles?: InputMaybe<Array<InputMaybe<ProductOptionBundleInput>>>;
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  smsTemplate?: InputMaybe<Scalars['ID']>;
  thumbnail?: InputMaybe<Scalars['ID']>;
};

export type ProductCategoryRelationResponseCollection = {
  __typename?: 'ProductCategoryRelationResponseCollection';
  data: Array<ProductCategoryEntity>;
};

export type ProductEntity = {
  __typename?: 'ProductEntity';
  attributes?: Maybe<Product>;
  id?: Maybe<Scalars['ID']>;
  /** 이 제품이 유저가 즐겨찾기를 체크한 제품인가? */
  isFavorite?: Maybe<Scalars['Boolean']>;
};

export type ProductEntityResponse = {
  __typename?: 'ProductEntityResponse';
  data?: Maybe<ProductEntity>;
};

export type ProductEntityResponseCollection = {
  __typename?: 'ProductEntityResponseCollection';
  data: Array<ProductEntity>;
  meta: ResponseCollectionMeta;
};

export type ProductFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ProductFiltersInput>>>;
  category?: InputMaybe<ProductCategoryFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  inventory?: InputMaybe<IntFilterInput>;
  isInfiniteInventory?: InputMaybe<BooleanFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ProductFiltersInput>;
  optionBundles?: InputMaybe<ProductOptionBundleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ProductFiltersInput>>>;
  price?: InputMaybe<IntFilterInput>;
  priority?: InputMaybe<IntFilterInput>;
  state?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ProductInput = {
  category?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  inventory?: InputMaybe<Scalars['Int']>;
  isInfiniteInventory?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  optionBundles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  price?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<Scalars['Int']>;
  /** 같이 추가할 옵션 번들의 정보 */
  productOptionBundles?: InputMaybe<Array<InputMaybe<ProductOptionBundleInput>>>;
  state?: InputMaybe<Enum_Product_State>;
  thumbnail?: InputMaybe<Scalars['ID']>;
};

export type ProductOption = {
  __typename?: 'ProductOption';
  bundle?: Maybe<ProductOptionBundleEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductOptionBundle = {
  __typename?: 'ProductOptionBundle';
  createdAt?: Maybe<Scalars['DateTime']>;
  defaultOption?: Maybe<ProductOptionEntityResponse>;
  name: Scalars['String'];
  options?: Maybe<ProductOptionRelationResponseCollection>;
  type: Enum_Productoptionbundle_Type;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ProductOptionBundleOptionsArgs = {
  filters?: InputMaybe<ProductOptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ProductOptionBundleEntity = {
  __typename?: 'ProductOptionBundleEntity';
  attributes?: Maybe<ProductOptionBundle>;
  id?: Maybe<Scalars['ID']>;
};

export type ProductOptionBundleEntityResponse = {
  __typename?: 'ProductOptionBundleEntityResponse';
  data?: Maybe<ProductOptionBundleEntity>;
};

export type ProductOptionBundleEntityResponseCollection = {
  __typename?: 'ProductOptionBundleEntityResponseCollection';
  data: Array<ProductOptionBundleEntity>;
  meta: ResponseCollectionMeta;
};

export type ProductOptionBundleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ProductOptionBundleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  defaultOption?: InputMaybe<ProductOptionFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ProductOptionBundleFiltersInput>;
  options?: InputMaybe<ProductOptionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ProductOptionBundleFiltersInput>>>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ProductOptionBundleInput = {
  defaultOption?: InputMaybe<Scalars['ID']>;
  /** 옵션 번들의 기본 옵션을 정하는 인덱스 */
  defaultOptionIndex?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** 같이 추가할 옵션의 정보 */
  productOptions?: InputMaybe<Array<InputMaybe<ProductOptionInput>>>;
  type?: InputMaybe<Enum_Productoptionbundle_Type>;
};

export type ProductOptionBundleRelationResponseCollection = {
  __typename?: 'ProductOptionBundleRelationResponseCollection';
  data: Array<ProductOptionBundleEntity>;
};

export type ProductOptionEntity = {
  __typename?: 'ProductOptionEntity';
  attributes?: Maybe<ProductOption>;
  id?: Maybe<Scalars['ID']>;
};

export type ProductOptionEntityResponse = {
  __typename?: 'ProductOptionEntityResponse';
  data?: Maybe<ProductOptionEntity>;
};

export type ProductOptionEntityResponseCollection = {
  __typename?: 'ProductOptionEntityResponseCollection';
  data: Array<ProductOptionEntity>;
  meta: ResponseCollectionMeta;
};

export type ProductOptionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ProductOptionFiltersInput>>>;
  bundle?: InputMaybe<ProductOptionBundleFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ProductOptionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ProductOptionFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ProductOptionInput = {
  bundle?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProductOptionRelationResponseCollection = {
  __typename?: 'ProductOptionRelationResponseCollection';
  data: Array<ProductOptionEntity>;
};

export type ProductRelationResponseCollection = {
  __typename?: 'ProductRelationResponseCollection';
  data: Array<ProductEntity>;
};

export type Query = {
  __typename?: 'Query';
  article?: Maybe<ArticleEntityResponse>;
  articles?: Maybe<ArticleEntityResponseCollection>;
  branch?: Maybe<CustomBranchEntityResponse>;
  branches?: Maybe<CustomBranchEntityResponseCollection>;
  businessHour?: Maybe<BusinessHourEntityResponse>;
  businessHours?: Maybe<BusinessHourEntityResponseCollection>;
  comment?: Maybe<CommentEntityResponse>;
  comments?: Maybe<CommentEntityResponseCollection>;
  coupon?: Maybe<CouponEntityResponse>;
  couponTemplate?: Maybe<CouponTemplateEntityResponse>;
  couponTemplates?: Maybe<CouponTemplateEntityResponseCollection>;
  coupons?: Maybe<CouponEntityResponseCollection>;
  exceptionalCloseDate?: Maybe<ExceptionalCloseDateEntityResponse>;
  exceptionalCloseDates?: Maybe<ExceptionalCloseDateEntityResponseCollection>;
  favorite?: Maybe<FavoriteEntityResponse>;
  favorites?: Maybe<FavoriteEntityResponseCollection>;
  gift?: Maybe<GiftEntityResponse>;
  giftCard?: Maybe<GiftCardEntityResponse>;
  giftCards?: Maybe<GiftCardEntityResponseCollection>;
  gifts?: Maybe<GiftEntityResponseCollection>;
  homeBoard?: Maybe<HomeBoardEntityResponse>;
  homeBoards?: Maybe<HomeBoardEntityResponseCollection>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
  monthlyOrderStatistics?: Maybe<MonthlyOrderStatisticsResponseType>;
  order?: Maybe<OrderEntityResponse>;
  orders?: Maybe<OrderEntityResponseCollection>;
  periodOrderStatistics?: Maybe<PeriodOrderStatisticsResponseType>;
  product?: Maybe<CustomProductEntityResponse>;
  productCategories?: Maybe<ProductCategoryEntityResponseCollection>;
  productCategory?: Maybe<ProductCategoryEntityResponse>;
  productOption?: Maybe<ProductOptionEntityResponse>;
  productOptionBundle?: Maybe<ProductOptionBundleEntityResponse>;
  productOptionBundles?: Maybe<ProductOptionBundleEntityResponseCollection>;
  productOptions?: Maybe<ProductOptionEntityResponseCollection>;
  products?: Maybe<ProductEntityResponseCollection>;
  smsAlert?: Maybe<SmsAlertEntityResponse>;
  smsAlerts?: Maybe<SmsAlertEntityResponseCollection>;
  stamp?: Maybe<StampEntityResponse>;
  stamps?: Maybe<StampEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  userOrderStatistics?: Maybe<UserOrderStatisticsResponseType>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  weeklyOrderStatistics?: Maybe<WeeklyOrderStatisticsResponseType>;
  whitelist?: Maybe<WhiteListResponseType>;
};


export type QueryArticleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryArticlesArgs = {
  filters?: InputMaybe<ArticleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBranchArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBranchesArgs = {
  filters?: InputMaybe<BranchFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryBusinessHourArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryBusinessHoursArgs = {
  filters?: InputMaybe<BusinessHourFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCommentArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCommentsArgs = {
  filters?: InputMaybe<CommentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCouponArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCouponTemplateArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCouponTemplatesArgs = {
  filters?: InputMaybe<CouponTemplateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCouponsArgs = {
  filters?: InputMaybe<CouponFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryExceptionalCloseDateArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryExceptionalCloseDatesArgs = {
  filters?: InputMaybe<ExceptionalCloseDateFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryFavoriteArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryFavoritesArgs = {
  filters?: InputMaybe<FavoriteFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGiftArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGiftCardArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGiftCardsArgs = {
  filters?: InputMaybe<GiftCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGiftsArgs = {
  filters?: InputMaybe<GiftFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryHomeBoardArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryHomeBoardsArgs = {
  filters?: InputMaybe<HomeBoardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryMonthlyOrderStatisticsArgs = {
  options?: InputMaybe<MonthlyOrderStatisticsInputType>;
};


export type QueryOrderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryOrdersArgs = {
  filters?: InputMaybe<OrderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryPeriodOrderStatisticsArgs = {
  options?: InputMaybe<PeriodOrderStatisticsInputType>;
};


export type QueryProductArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryProductCategoriesArgs = {
  filters?: InputMaybe<ProductCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryProductCategoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryProductOptionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryProductOptionBundleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryProductOptionBundlesArgs = {
  filters?: InputMaybe<ProductOptionBundleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryProductOptionsArgs = {
  filters?: InputMaybe<ProductOptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryProductsArgs = {
  filters?: InputMaybe<ProductFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySmsAlertArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySmsAlertsArgs = {
  filters?: InputMaybe<SmsAlertFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryStampArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryStampsArgs = {
  filters?: InputMaybe<StampFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUserOrderStatisticsArgs = {
  options?: InputMaybe<UserOrderStatisticsInputType>;
};


export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryWeeklyOrderStatisticsArgs = {
  options?: InputMaybe<WeeklyOrderStatisticsInputType>;
};


export type QueryWhitelistArgs = {
  isAdminRequest?: InputMaybe<Scalars['Boolean']>;
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type SaleEntity = {
  __typename?: 'SaleEntity';
  couponUsedAmount?: Maybe<Scalars['Int']>;
  couponUsedQuantity?: Maybe<Scalars['Int']>;
  giftUsedAmount?: Maybe<Scalars['Int']>;
  giftUsedQuantity?: Maybe<Scalars['Int']>;
};

export type SmsAlert = {
  __typename?: 'SmsAlert';
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  trigger: Enum_Smsalert_Trigger;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SmsAlertEntity = {
  __typename?: 'SmsAlertEntity';
  attributes?: Maybe<SmsAlert>;
  id?: Maybe<Scalars['ID']>;
};

export type SmsAlertEntityResponse = {
  __typename?: 'SmsAlertEntityResponse';
  data?: Maybe<SmsAlertEntity>;
};

export type SmsAlertEntityResponseCollection = {
  __typename?: 'SmsAlertEntityResponseCollection';
  data: Array<SmsAlertEntity>;
  meta: ResponseCollectionMeta;
};

export type SmsAlertFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SmsAlertFiltersInput>>>;
  content?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<SmsAlertFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SmsAlertFiltersInput>>>;
  trigger?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SmsAlertInput = {
  content?: InputMaybe<Scalars['String']>;
  trigger?: InputMaybe<Enum_Smsalert_Trigger>;
};

export type Stamp = {
  __typename?: 'Stamp';
  count: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdCoupon?: Maybe<CouponEntityResponse>;
  owner?: Maybe<UsersPermissionsUserEntityResponse>;
  state: Enum_Stamp_State;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StampEntity = {
  __typename?: 'StampEntity';
  attributes?: Maybe<Stamp>;
  id?: Maybe<Scalars['ID']>;
};

export type StampEntityResponse = {
  __typename?: 'StampEntityResponse';
  data?: Maybe<StampEntity>;
};

export type StampEntityResponseCollection = {
  __typename?: 'StampEntityResponseCollection';
  data: Array<StampEntity>;
  meta: ResponseCollectionMeta;
};

export type StampFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<StampFiltersInput>>>;
  count?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  createdCoupon?: InputMaybe<CouponFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<StampFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<StampFiltersInput>>>;
  owner?: InputMaybe<UsersPermissionsUserFiltersInput>;
  state?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type StampInput = {
  count?: InputMaybe<Scalars['Int']>;
  createdCoupon?: InputMaybe<Scalars['ID']>;
  owner?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Enum_Stamp_State>;
};

export type StatisticsObject = {
  __typename?: 'StatisticsObject';
  companyCode?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Int']>;
  totalQuantity?: Maybe<Scalars['Int']>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  containsi?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']>;
  notContainsi?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type TimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Time']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Time']>>>;
  contains?: InputMaybe<Scalars['Time']>;
  containsi?: InputMaybe<Scalars['Time']>;
  endsWith?: InputMaybe<Scalars['Time']>;
  eq?: InputMaybe<Scalars['Time']>;
  gt?: InputMaybe<Scalars['Time']>;
  gte?: InputMaybe<Scalars['Time']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Time']>>>;
  lt?: InputMaybe<Scalars['Time']>;
  lte?: InputMaybe<Scalars['Time']>;
  ne?: InputMaybe<Scalars['Time']>;
  not?: InputMaybe<TimeFilterInput>;
  notContains?: InputMaybe<Scalars['Time']>;
  notContainsi?: InputMaybe<Scalars['Time']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Time']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Time']>>>;
  startsWith?: InputMaybe<Scalars['Time']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  ext?: InputMaybe<Scalars['String']>;
  formats?: InputMaybe<Scalars['JSON']>;
  hash?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  mime?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  previewUrl?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_metadata?: InputMaybe<Scalars['JSON']>;
  size?: InputMaybe<Scalars['Float']>;
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type UserOrderStatisticsEntity = {
  __typename?: 'UserOrderStatisticsEntity';
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Int']>;
  totalQuantity?: Maybe<Scalars['Int']>;
};

export type UserOrderStatisticsInputType = {
  /** 조회를 마칠 날자 */
  endAt?: InputMaybe<Scalars['Date']>;
  /** 조회를 시작할 날자 */
  startAt?: InputMaybe<Scalars['Date']>;
};

export type UserOrderStatisticsResponseType = {
  __typename?: 'UserOrderStatisticsResponseType';
  orders?: Maybe<Array<Maybe<UserOrderStatisticsEntity>>>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String'];
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  birthday?: Maybe<Scalars['Date']>;
  blocked?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  employeeId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  nickname?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['Int']>;
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  birthday?: InputMaybe<DateFilterInput>;
  blocked?: InputMaybe<BooleanFilterInput>;
  company?: InputMaybe<StringFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  employeeId?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isDeleted?: InputMaybe<BooleanFilterInput>;
  nickname?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  phoneNumber?: InputMaybe<StringFilterInput>;
  pid?: InputMaybe<IntFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
};

export type UsersPermissionsUserInput = {
  birthday?: InputMaybe<Scalars['Date']>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  company?: InputMaybe<Scalars['String']>;
  confirmationToken?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  nickname?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  pid?: InputMaybe<Scalars['Int']>;
  provider?: InputMaybe<Scalars['String']>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type WeeklyOrderStatisticsEntity = {
  __typename?: 'WeeklyOrderStatisticsEntity';
  companyTotals?: Maybe<Array<Maybe<StatisticsObject>>>;
  month?: Maybe<Scalars['String']>;
  sale?: Maybe<SaleEntity>;
  totalAmount?: Maybe<Scalars['Int']>;
  totalQuantity?: Maybe<Scalars['Int']>;
  week?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type WeeklyOrderStatisticsInputType = {
  /** 조회할 지점 리스트 */
  branch?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** 조회를 마칠 날자 */
  endAt?: InputMaybe<Scalars['Date']>;
  /** 조회를 시작할 날자 */
  startAt?: InputMaybe<Scalars['Date']>;
};

export type WeeklyOrderStatisticsResponseType = {
  __typename?: 'WeeklyOrderStatisticsResponseType';
  statistics?: Maybe<Array<Maybe<WeeklyOrderStatisticsEntity>>>;
};

export type WhiteListEntity = {
  __typename?: 'WhiteListEntity';
  code: Scalars['String'];
  /** 도메인 */
  domain: Scalars['String'];
  /** 관계사 이름 */
  name: Scalars['String'];
};

export type WhiteListResponseType = {
  __typename?: 'WhiteListResponseType';
  data: Array<Maybe<WhiteListEntity>>;
};

export enum GiftState {
  UnUsed = 'unUsed',
  Used = 'used'
}

export enum OrderState {
  Canceled = 'canceled',
  Done = 'done',
  Gifted = 'gifted',
  InProgress = 'inProgress',
  Ordered = 'ordered'
}

export type CreateArticleMutationVariables = Exact<{
  data: ArticleInput;
}>;


export type CreateArticleMutation = { __typename?: 'Mutation', createArticle?: { __typename?: 'ArticleEntityResponse', data?: { __typename?: 'ArticleEntity', id?: string | null } | null } | null };

export type CreateCommentMutationVariables = Exact<{
  data: CommentInput;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment?: { __typename?: 'CommentEntityResponse', data?: { __typename?: 'CommentEntity', id?: string | null } | null } | null };

export type CreateFavoriteMutationVariables = Exact<{
  data: FavoriteInput;
}>;


export type CreateFavoriteMutation = { __typename?: 'Mutation', createFavorite?: { __typename?: 'FavoriteEntityResponse', data?: { __typename?: 'FavoriteEntity', id?: string | null } | null } | null };

export type CreateGiftMutationVariables = Exact<{
  data: GiftInput;
}>;


export type CreateGiftMutation = { __typename?: 'Mutation', createGift?: { __typename?: 'GiftEntityResponse', data?: { __typename?: 'GiftEntity', id?: string | null } | null } | null };

export type DeleteArticleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteArticleMutation = { __typename?: 'Mutation', deleteArticle?: { __typename?: 'ArticleEntityResponse', data?: { __typename?: 'ArticleEntity', id?: string | null } | null } | null };

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCommentMutation = { __typename?: 'Mutation', deleteComment?: { __typename?: 'CommentEntityResponse', data?: { __typename?: 'CommentEntity', id?: string | null } | null } | null };

export type DeleteFavoriteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteFavoriteMutation = { __typename?: 'Mutation', deleteFavorite?: { __typename?: 'FavoriteEntityResponse', data?: { __typename?: 'FavoriteEntity', id?: string | null } | null } | null };

export type DeleteOrderMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOrderMutation = { __typename?: 'Mutation', deleteOrder?: { __typename?: 'OrderEntityResponse', data?: { __typename?: 'OrderEntity', id?: string | null } | null } | null };

export type GetArticleQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type GetArticleQuery = { __typename?: 'Query', article?: { __typename?: 'ArticleEntityResponse', data?: { __typename?: 'ArticleEntity', id?: string | null, attributes?: { __typename?: 'Article', title?: string | null, createdAt?: any | null, content?: string | null, homeBoard?: { __typename?: 'HomeBoardEntityResponse', data?: { __typename?: 'HomeBoardEntity', attributes?: { __typename?: 'HomeBoard', thumbnail?: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } | null } | null } | null } | null } | null } | null } | null };

export type GetArticlesQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
  filters?: InputMaybe<ArticleFiltersInput>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetArticlesQuery = { __typename?: 'Query', articles?: { __typename?: 'ArticleEntityResponseCollection', data: Array<{ __typename?: 'ArticleEntity', id?: string | null, commentCount?: number | null, attributes?: { __typename?: 'Article', title?: string | null, createdAt?: any | null, content?: string | null, homeBoard?: { __typename?: 'HomeBoardEntityResponse', data?: { __typename?: 'HomeBoardEntity', attributes?: { __typename?: 'HomeBoard', thumbnail?: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } | null } | null } | null } | null, writer?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', email: string, nickname?: string | null } | null } | null } | null } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', total: number, page: number, pageCount: number } } } | null };

export type GetBranchQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type GetBranchQuery = { __typename?: 'Query', branch?: { __typename?: 'CustomBranchEntityResponse', data: { __typename?: 'BranchEntity', isOpen?: boolean | null, attributes?: { __typename?: 'Branch', color: string } | null } } | null };

export type GetBranchesQueryVariables = Exact<{
  filters?: InputMaybe<BranchFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetBranchesQuery = { __typename?: 'Query', branches?: { __typename?: 'CustomBranchEntityResponseCollection', data: Array<{ __typename?: 'BranchEntity', id?: string | null, isOpen?: boolean | null, attributes?: { __typename?: 'Branch', name: string, color: string } | null }> } | null };

export type GetCommentsQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
  filters?: InputMaybe<CommentFiltersInput>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetCommentsQuery = { __typename?: 'Query', comments?: { __typename?: 'CommentEntityResponseCollection', data: Array<{ __typename?: 'CommentEntity', id?: string | null, attributes?: { __typename?: 'Comment', content: string, createdAt?: any | null, writer?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', attributes?: { __typename?: 'UsersPermissionsUser', nickname?: string | null, email: string } | null } | null } | null } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', total: number, page: number, pageCount: number } } } | null };

export type GetCompanyWhitelistQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompanyWhitelistQuery = { __typename?: 'Query', whitelist?: { __typename?: 'WhiteListResponseType', data: Array<{ __typename?: 'WhiteListEntity', name: string, domain: string } | null> } | null };

export type GetCouponQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type GetCouponQuery = { __typename?: 'Query', coupon?: { __typename?: 'CouponEntityResponse', data?: { __typename?: 'CouponEntity', attributes?: { __typename?: 'Coupon', state: Enum_Coupon_State, createdAt?: any | null, couponTemplate?: { __typename?: 'CouponTemplateEntityResponse', data?: { __typename?: 'CouponTemplateEntity', attributes?: { __typename?: 'CouponTemplate', name: string, availableCategories?: { __typename?: 'ProductCategoryRelationResponseCollection', data: Array<{ __typename?: 'ProductCategoryEntity', id?: string | null, attributes?: { __typename?: 'ProductCategory', name: string, branch?: { __typename?: 'BranchEntityResponse', data?: { __typename?: 'BranchEntity', attributes?: { __typename?: 'Branch', name: string } | null } | null } | null } | null }> } | null } | null } | null } | null, stamp?: { __typename?: 'StampEntityResponse', data?: { __typename?: 'StampEntity', id?: string | null } | null } | null } | null } | null } | null };

export type GetCouponsQueryVariables = Exact<{
  filters?: InputMaybe<CouponFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetCouponsQuery = { __typename?: 'Query', coupons?: { __typename?: 'CouponEntityResponseCollection', data: Array<{ __typename?: 'CouponEntity', id?: string | null, attributes?: { __typename?: 'Coupon', creator: string, usedAt?: any | null, state: Enum_Coupon_State, createdAt?: any | null, updatedAt?: any | null, couponTemplate?: { __typename?: 'CouponTemplateEntityResponse', data?: { __typename?: 'CouponTemplateEntity', id?: string | null, attributes?: { __typename?: 'CouponTemplate', name: string, availableCategories?: { __typename?: 'ProductCategoryRelationResponseCollection', data: Array<{ __typename?: 'ProductCategoryEntity', id?: string | null, attributes?: { __typename?: 'ProductCategory', name: string } | null }> } | null } | null } | null } | null } | null }> } | null };

export type GetCouponsWithPaginationQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
  filters?: InputMaybe<CouponFiltersInput>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetCouponsWithPaginationQuery = { __typename?: 'Query', coupons?: { __typename?: 'CouponEntityResponseCollection', data: Array<{ __typename?: 'CouponEntity', id?: string | null, attributes?: { __typename?: 'Coupon', creator: string, state: Enum_Coupon_State, createdAt?: any | null, stamp?: { __typename?: 'StampEntityResponse', data?: { __typename?: 'StampEntity', id?: string | null } | null } | null, couponTemplate?: { __typename?: 'CouponTemplateEntityResponse', data?: { __typename?: 'CouponTemplateEntity', id?: string | null, attributes?: { __typename?: 'CouponTemplate', name: string } | null } | null } | null } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', total: number, page: number, pageCount: number } } } | null };

export type GetFavoritesQueryVariables = Exact<{
  filters?: InputMaybe<FavoriteFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetFavoritesQuery = { __typename?: 'Query', favorites?: { __typename?: 'FavoriteEntityResponseCollection', data: Array<{ __typename?: 'FavoriteEntity', id?: string | null, attributes?: { __typename?: 'Favorite', product?: { __typename?: 'ProductEntityResponse', data?: { __typename?: 'ProductEntity', id?: string | null, attributes?: { __typename?: 'Product', description: string, name: string, price: number, state: Enum_Product_State, inventory: number, isInfiniteInventory: boolean, category?: { __typename?: 'ProductCategoryEntityResponse', data?: { __typename?: 'ProductCategoryEntity', id?: string | null, attributes?: { __typename?: 'ProductCategory', thumbnail: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } } | null } | null } | null, thumbnail?: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', id?: string | null, attributes?: { __typename?: 'UploadFile', url: string } | null } | null } | null } | null } | null } | null } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', total: number, page: number, pageSize: number, pageCount: number } } } | null };

export type GetGiftQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type GetGiftQuery = { __typename?: 'Query', gift?: { __typename?: 'GiftEntityResponse', data?: { __typename?: 'GiftEntity', id?: string | null, attributes?: { __typename?: 'Gift', message: string, price: number, name: string, state: Enum_Gift_State, createdAt?: any | null, sender?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', attributes?: { __typename?: 'UsersPermissionsUser', nickname?: string | null, email: string } | null } | null } | null, giftCard?: { __typename?: 'GiftCardEntityResponse', data?: { __typename?: 'GiftCardEntity', attributes?: { __typename?: 'GiftCard', image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } } | null } | null } | null } | null } | null } | null };

export type GetGiftCardsQueryVariables = Exact<{
  filters?: InputMaybe<GiftCardFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetGiftCardsQuery = { __typename?: 'Query', giftCards?: { __typename?: 'GiftCardEntityResponseCollection', data: Array<{ __typename?: 'GiftCardEntity', id?: string | null, attributes?: { __typename?: 'GiftCard', type: Enum_Giftcard_Type, image: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', id?: string | null, attributes?: { __typename?: 'UploadFile', url: string } | null } | null } } | null }> } | null };

export type GetGiftsQueryVariables = Exact<{
  filters?: InputMaybe<GiftFiltersInput>;
}>;


export type GetGiftsQuery = { __typename?: 'Query', gifts?: { __typename?: 'GiftEntityResponseCollection', data: Array<{ __typename?: 'GiftEntity', id?: string | null, attributes?: { __typename?: 'Gift', name: string, price: number, state: Enum_Gift_State, originBranch?: { __typename?: 'BranchEntityResponse', data?: { __typename?: 'BranchEntity', id?: string | null } | null } | null } | null }> } | null };

export type GetGiftsWithPaginationQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
  filters?: InputMaybe<GiftFiltersInput>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetGiftsWithPaginationQuery = { __typename?: 'Query', gifts?: { __typename?: 'GiftEntityResponseCollection', data: Array<{ __typename?: 'GiftEntity', id?: string | null, attributes?: { __typename?: 'Gift', price: number, name: string, createdAt?: any | null, state: Enum_Gift_State, sender?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', attributes?: { __typename?: 'UsersPermissionsUser', nickname?: string | null, email: string } | null } | null } | null } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', total: number, page: number, pageCount: number } } } | null };

export type GetHomeBoardQueryVariables = Exact<{
  filters?: InputMaybe<HomeBoardFiltersInput>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetHomeBoardQuery = { __typename?: 'Query', homeBoards?: { __typename?: 'HomeBoardEntityResponseCollection', data: Array<{ __typename?: 'HomeBoardEntity', id?: string | null, attributes?: { __typename?: 'HomeBoard', exposedArticle?: { __typename?: 'ArticleEntityResponse', data?: { __typename?: 'ArticleEntity', id?: string | null, attributes?: { __typename?: 'Article', type: Enum_Article_Type, content?: string | null, title?: string | null } | null } | null } | null, thumbnail?: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } | null } | null }> } | null };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', me?: { __typename?: 'UsersPermissionsMe', id: string, email?: string | null, role?: { __typename?: 'UsersPermissionsMeRole', id: string, type?: string | null } | null } | null };

export type GetOrdersQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationArg>;
  filters?: InputMaybe<OrderFiltersInput>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetOrdersQuery = { __typename?: 'Query', orders?: { __typename?: 'OrderEntityResponseCollection', data: Array<{ __typename?: 'OrderEntity', id?: string | null, attributes?: { __typename?: 'Order', request: string, productOptions: string, amount: number, state: Enum_Order_State, quantity: number, createdAt?: any | null, product?: { __typename?: 'ProductEntityResponse', data?: { __typename?: 'ProductEntity', id?: string | null, attributes?: { __typename?: 'Product', name: string } | null } | null } | null, branch?: { __typename?: 'BranchEntityResponse', data?: { __typename?: 'BranchEntity', attributes?: { __typename?: 'Branch', name: string } | null } | null } | null, gift?: { __typename?: 'GiftEntityResponse', data?: { __typename?: 'GiftEntity', attributes?: { __typename?: 'Gift', receiver?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', attributes?: { __typename?: 'UsersPermissionsUser', nickname?: string | null, email: string } | null } | null } | null } | null } | null } | null } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', total: number, page: number, pageCount: number } } } | null };

export type GetProductQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  productOptionSort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetProductQuery = { __typename?: 'Query', product?: { __typename?: 'CustomProductEntityResponse', data: { __typename?: 'ProductEntity', id?: string | null, isFavorite?: boolean | null, attributes?: { __typename?: 'Product', description: string, name: string, price: number, state: Enum_Product_State, inventory: number, isInfiniteInventory: boolean, category?: { __typename?: 'ProductCategoryEntityResponse', data?: { __typename?: 'ProductCategoryEntity', id?: string | null, attributes?: { __typename?: 'ProductCategory', thumbnail: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } } | null } | null } | null, thumbnail?: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', id?: string | null, attributes?: { __typename?: 'UploadFile', url: string } | null } | null } | null, optionBundles?: { __typename?: 'ProductOptionBundleRelationResponseCollection', data: Array<{ __typename?: 'ProductOptionBundleEntity', id?: string | null, attributes?: { __typename?: 'ProductOptionBundle', name: string, type: Enum_Productoptionbundle_Type, options?: { __typename?: 'ProductOptionRelationResponseCollection', data: Array<{ __typename?: 'ProductOptionEntity', id?: string | null, attributes?: { __typename?: 'ProductOption', name: string } | null }> } | null, defaultOption?: { __typename?: 'ProductOptionEntityResponse', data?: { __typename?: 'ProductOptionEntity', id?: string | null, attributes?: { __typename?: 'ProductOption', name: string } | null } | null } | null } | null }> } | null } | null } } | null };

export type GetProductCategoriesQueryVariables = Exact<{
  filters?: InputMaybe<ProductCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetProductCategoriesQuery = { __typename?: 'Query', productCategories?: { __typename?: 'ProductCategoryEntityResponseCollection', data: Array<{ __typename?: 'ProductCategoryEntity', id?: string | null, attributes?: { __typename?: 'ProductCategory', name: string } | null }> } | null };

export type GetProductsQueryVariables = Exact<{
  filters?: InputMaybe<ProductFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetProductsQuery = { __typename?: 'Query', products?: { __typename?: 'ProductEntityResponseCollection', data: Array<{ __typename?: 'ProductEntity', id?: string | null, attributes?: { __typename?: 'Product', description: string, name: string, price: number, state: Enum_Product_State, inventory: number, isInfiniteInventory: boolean, createdAt?: any | null, updatedAt?: any | null, category?: { __typename?: 'ProductCategoryEntityResponse', data?: { __typename?: 'ProductCategoryEntity', id?: string | null, attributes?: { __typename?: 'ProductCategory', thumbnail: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', attributes?: { __typename?: 'UploadFile', url: string } | null } | null } } | null } | null } | null, thumbnail?: { __typename?: 'UploadFileEntityResponse', data?: { __typename?: 'UploadFileEntity', id?: string | null, attributes?: { __typename?: 'UploadFile', url: string } | null } | null } | null } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', total: number, page: number, pageSize: number, pageCount: number } } } | null };

export type GetStampQueryVariables = Exact<{
  filters?: InputMaybe<StampFiltersInput>;
}>;


export type GetStampQuery = { __typename?: 'Query', stamps?: { __typename?: 'StampEntityResponseCollection', data: Array<{ __typename?: 'StampEntity', id?: string | null, attributes?: { __typename?: 'Stamp', count: number } | null }> } | null };

export type GetUserDetailQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type GetUserDetailQuery = { __typename?: 'Query', usersPermissionsUser?: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', email: string, phoneNumber?: string | null, nickname?: string | null, company?: string | null, birthday?: any | null } | null } | null } | null };

export type GetUsersQueryVariables = Exact<{
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetUsersQuery = { __typename?: 'Query', usersPermissionsUsers?: { __typename?: 'UsersPermissionsUserEntityResponseCollection', data: Array<{ __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', pid?: number | null, username: string, email: string, nickname?: string | null } | null }>, meta: { __typename?: 'ResponseCollectionMeta', pagination: { __typename?: 'Pagination', total: number, page: number, pageSize: number, pageCount: number } } } | null };

export type MadeOrderMutationVariables = Exact<{
  orderData?: InputMaybe<MadeOrderInputType>;
  bulkOrderDatas?: InputMaybe<BulkMadeOrderInputType>;
}>;


export type MadeOrderMutation = { __typename?: 'Mutation', madeOrder?: { __typename?: 'MadeOrderType', message: string, ok: boolean } | null };

export type UpdateFavoriteMutationVariables = Exact<{
  id: Scalars['ID'];
  data: FavoriteInput;
}>;


export type UpdateFavoriteMutation = { __typename?: 'Mutation', updateFavorite?: { __typename?: 'FavoriteEntityResponse', data?: { __typename?: 'FavoriteEntity', id?: string | null } | null } | null };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  data: UsersPermissionsUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUsersPermissionsUser: { __typename?: 'UsersPermissionsUserEntityResponse', data?: { __typename?: 'UsersPermissionsUserEntity', id?: string | null, attributes?: { __typename?: 'UsersPermissionsUser', company?: string | null, birthday?: any | null, phoneNumber?: string | null, nickname?: string | null } | null } | null } };


export const CreateArticleDocument = `
    mutation CreateArticle($data: ArticleInput!) {
  createArticle(data: $data) {
    data {
      id
    }
  }
}
    `;
export const useCreateArticleMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateArticleMutation, TError, CreateArticleMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateArticleMutation, TError, CreateArticleMutationVariables, TContext>(
      ['CreateArticle'],
      (variables?: CreateArticleMutationVariables) => fetcher<CreateArticleMutation, CreateArticleMutationVariables>(client, CreateArticleDocument, variables, headers)(),
      options
    );
useCreateArticleMutation.fetcher = (client: GraphQLClient, variables: CreateArticleMutationVariables, headers?: RequestInit['headers']) => fetcher<CreateArticleMutation, CreateArticleMutationVariables>(client, CreateArticleDocument, variables, headers);
export const CreateCommentDocument = `
    mutation CreateComment($data: CommentInput!) {
  createComment(data: $data) {
    data {
      id
    }
  }
}
    `;
export const useCreateCommentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateCommentMutation, TError, CreateCommentMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateCommentMutation, TError, CreateCommentMutationVariables, TContext>(
      ['CreateComment'],
      (variables?: CreateCommentMutationVariables) => fetcher<CreateCommentMutation, CreateCommentMutationVariables>(client, CreateCommentDocument, variables, headers)(),
      options
    );
useCreateCommentMutation.fetcher = (client: GraphQLClient, variables: CreateCommentMutationVariables, headers?: RequestInit['headers']) => fetcher<CreateCommentMutation, CreateCommentMutationVariables>(client, CreateCommentDocument, variables, headers);
export const CreateFavoriteDocument = `
    mutation CreateFavorite($data: FavoriteInput!) {
  createFavorite(data: $data) {
    data {
      id
    }
  }
}
    `;
export const useCreateFavoriteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateFavoriteMutation, TError, CreateFavoriteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateFavoriteMutation, TError, CreateFavoriteMutationVariables, TContext>(
      ['CreateFavorite'],
      (variables?: CreateFavoriteMutationVariables) => fetcher<CreateFavoriteMutation, CreateFavoriteMutationVariables>(client, CreateFavoriteDocument, variables, headers)(),
      options
    );
useCreateFavoriteMutation.fetcher = (client: GraphQLClient, variables: CreateFavoriteMutationVariables, headers?: RequestInit['headers']) => fetcher<CreateFavoriteMutation, CreateFavoriteMutationVariables>(client, CreateFavoriteDocument, variables, headers);
export const CreateGiftDocument = `
    mutation CreateGift($data: GiftInput!) {
  createGift(data: $data) {
    data {
      id
    }
  }
}
    `;
export const useCreateGiftMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateGiftMutation, TError, CreateGiftMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateGiftMutation, TError, CreateGiftMutationVariables, TContext>(
      ['CreateGift'],
      (variables?: CreateGiftMutationVariables) => fetcher<CreateGiftMutation, CreateGiftMutationVariables>(client, CreateGiftDocument, variables, headers)(),
      options
    );
useCreateGiftMutation.fetcher = (client: GraphQLClient, variables: CreateGiftMutationVariables, headers?: RequestInit['headers']) => fetcher<CreateGiftMutation, CreateGiftMutationVariables>(client, CreateGiftDocument, variables, headers);
export const DeleteArticleDocument = `
    mutation DeleteArticle($id: ID!) {
  deleteArticle(id: $id) {
    data {
      id
    }
  }
}
    `;
export const useDeleteArticleMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteArticleMutation, TError, DeleteArticleMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteArticleMutation, TError, DeleteArticleMutationVariables, TContext>(
      ['DeleteArticle'],
      (variables?: DeleteArticleMutationVariables) => fetcher<DeleteArticleMutation, DeleteArticleMutationVariables>(client, DeleteArticleDocument, variables, headers)(),
      options
    );
useDeleteArticleMutation.fetcher = (client: GraphQLClient, variables: DeleteArticleMutationVariables, headers?: RequestInit['headers']) => fetcher<DeleteArticleMutation, DeleteArticleMutationVariables>(client, DeleteArticleDocument, variables, headers);
export const DeleteCommentDocument = `
    mutation DeleteComment($id: ID!) {
  deleteComment(id: $id) {
    data {
      id
    }
  }
}
    `;
export const useDeleteCommentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteCommentMutation, TError, DeleteCommentMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteCommentMutation, TError, DeleteCommentMutationVariables, TContext>(
      ['DeleteComment'],
      (variables?: DeleteCommentMutationVariables) => fetcher<DeleteCommentMutation, DeleteCommentMutationVariables>(client, DeleteCommentDocument, variables, headers)(),
      options
    );
useDeleteCommentMutation.fetcher = (client: GraphQLClient, variables: DeleteCommentMutationVariables, headers?: RequestInit['headers']) => fetcher<DeleteCommentMutation, DeleteCommentMutationVariables>(client, DeleteCommentDocument, variables, headers);
export const DeleteFavoriteDocument = `
    mutation DeleteFavorite($id: ID!) {
  deleteFavorite(id: $id) {
    data {
      id
    }
  }
}
    `;
export const useDeleteFavoriteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteFavoriteMutation, TError, DeleteFavoriteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteFavoriteMutation, TError, DeleteFavoriteMutationVariables, TContext>(
      ['DeleteFavorite'],
      (variables?: DeleteFavoriteMutationVariables) => fetcher<DeleteFavoriteMutation, DeleteFavoriteMutationVariables>(client, DeleteFavoriteDocument, variables, headers)(),
      options
    );
useDeleteFavoriteMutation.fetcher = (client: GraphQLClient, variables: DeleteFavoriteMutationVariables, headers?: RequestInit['headers']) => fetcher<DeleteFavoriteMutation, DeleteFavoriteMutationVariables>(client, DeleteFavoriteDocument, variables, headers);
export const DeleteOrderDocument = `
    mutation DeleteOrder($id: ID!) {
  deleteOrder(id: $id) {
    data {
      id
    }
  }
}
    `;
export const useDeleteOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteOrderMutation, TError, DeleteOrderMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteOrderMutation, TError, DeleteOrderMutationVariables, TContext>(
      ['DeleteOrder'],
      (variables?: DeleteOrderMutationVariables) => fetcher<DeleteOrderMutation, DeleteOrderMutationVariables>(client, DeleteOrderDocument, variables, headers)(),
      options
    );
useDeleteOrderMutation.fetcher = (client: GraphQLClient, variables: DeleteOrderMutationVariables, headers?: RequestInit['headers']) => fetcher<DeleteOrderMutation, DeleteOrderMutationVariables>(client, DeleteOrderDocument, variables, headers);
export const GetArticleDocument = `
    query GetArticle($id: ID) {
  article(id: $id) {
    data {
      id
      attributes {
        title
        createdAt
        content
        homeBoard {
          data {
            attributes {
              thumbnail {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetArticleQuery = <
      TData = GetArticleQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetArticleQueryVariables,
      options?: UseQueryOptions<GetArticleQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetArticleQuery, TError, TData>(
      variables === undefined ? ['GetArticle'] : ['GetArticle', variables],
      fetcher<GetArticleQuery, GetArticleQueryVariables>(client, GetArticleDocument, variables, headers),
      options
    );

useGetArticleQuery.getKey = (variables?: GetArticleQueryVariables) => variables === undefined ? ['GetArticle'] : ['GetArticle', variables];
;

export const useInfiniteGetArticleQuery = <
      TData = GetArticleQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetArticleQueryVariables,
      client: GraphQLClient,
      variables?: GetArticleQueryVariables,
      options?: UseInfiniteQueryOptions<GetArticleQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetArticleQuery, TError, TData>(
      variables === undefined ? ['GetArticle.infinite'] : ['GetArticle.infinite', variables],
      (metaData) => fetcher<GetArticleQuery, GetArticleQueryVariables>(client, GetArticleDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetArticleQuery.getKey = (variables?: GetArticleQueryVariables) => variables === undefined ? ['GetArticle.infinite'] : ['GetArticle.infinite', variables];
;

useGetArticleQuery.fetcher = (client: GraphQLClient, variables?: GetArticleQueryVariables, headers?: RequestInit['headers']) => fetcher<GetArticleQuery, GetArticleQueryVariables>(client, GetArticleDocument, variables, headers);
export const GetArticlesDocument = `
    query GetArticles($pagination: PaginationArg, $filters: ArticleFiltersInput, $sort: [String]) {
  articles(pagination: $pagination, filters: $filters, sort: $sort) {
    data {
      id
      attributes {
        title
        createdAt
        homeBoard {
          data {
            attributes {
              thumbnail {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        content
        writer {
          data {
            id
            attributes {
              email
              nickname
            }
          }
        }
      }
      commentCount
    }
    meta {
      pagination {
        total
        page
        pageCount
      }
    }
  }
}
    `;
export const useGetArticlesQuery = <
      TData = GetArticlesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetArticlesQueryVariables,
      options?: UseQueryOptions<GetArticlesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetArticlesQuery, TError, TData>(
      variables === undefined ? ['GetArticles'] : ['GetArticles', variables],
      fetcher<GetArticlesQuery, GetArticlesQueryVariables>(client, GetArticlesDocument, variables, headers),
      options
    );

useGetArticlesQuery.getKey = (variables?: GetArticlesQueryVariables) => variables === undefined ? ['GetArticles'] : ['GetArticles', variables];
;

export const useInfiniteGetArticlesQuery = <
      TData = GetArticlesQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetArticlesQueryVariables,
      client: GraphQLClient,
      variables?: GetArticlesQueryVariables,
      options?: UseInfiniteQueryOptions<GetArticlesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetArticlesQuery, TError, TData>(
      variables === undefined ? ['GetArticles.infinite'] : ['GetArticles.infinite', variables],
      (metaData) => fetcher<GetArticlesQuery, GetArticlesQueryVariables>(client, GetArticlesDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetArticlesQuery.getKey = (variables?: GetArticlesQueryVariables) => variables === undefined ? ['GetArticles.infinite'] : ['GetArticles.infinite', variables];
;

useGetArticlesQuery.fetcher = (client: GraphQLClient, variables?: GetArticlesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetArticlesQuery, GetArticlesQueryVariables>(client, GetArticlesDocument, variables, headers);
export const GetBranchDocument = `
    query GetBranch($id: ID) {
  branch(id: $id) {
    data {
      isOpen
      attributes {
        color
      }
    }
  }
}
    `;
export const useGetBranchQuery = <
      TData = GetBranchQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetBranchQueryVariables,
      options?: UseQueryOptions<GetBranchQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetBranchQuery, TError, TData>(
      variables === undefined ? ['GetBranch'] : ['GetBranch', variables],
      fetcher<GetBranchQuery, GetBranchQueryVariables>(client, GetBranchDocument, variables, headers),
      options
    );

useGetBranchQuery.getKey = (variables?: GetBranchQueryVariables) => variables === undefined ? ['GetBranch'] : ['GetBranch', variables];
;

export const useInfiniteGetBranchQuery = <
      TData = GetBranchQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetBranchQueryVariables,
      client: GraphQLClient,
      variables?: GetBranchQueryVariables,
      options?: UseInfiniteQueryOptions<GetBranchQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetBranchQuery, TError, TData>(
      variables === undefined ? ['GetBranch.infinite'] : ['GetBranch.infinite', variables],
      (metaData) => fetcher<GetBranchQuery, GetBranchQueryVariables>(client, GetBranchDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetBranchQuery.getKey = (variables?: GetBranchQueryVariables) => variables === undefined ? ['GetBranch.infinite'] : ['GetBranch.infinite', variables];
;

useGetBranchQuery.fetcher = (client: GraphQLClient, variables?: GetBranchQueryVariables, headers?: RequestInit['headers']) => fetcher<GetBranchQuery, GetBranchQueryVariables>(client, GetBranchDocument, variables, headers);
export const GetBranchesDocument = `
    query GetBranches($filters: BranchFiltersInput, $pagination: PaginationArg, $sort: [String] = []) {
  branches(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        name
        color
      }
      isOpen
    }
  }
}
    `;
export const useGetBranchesQuery = <
      TData = GetBranchesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetBranchesQueryVariables,
      options?: UseQueryOptions<GetBranchesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetBranchesQuery, TError, TData>(
      variables === undefined ? ['GetBranches'] : ['GetBranches', variables],
      fetcher<GetBranchesQuery, GetBranchesQueryVariables>(client, GetBranchesDocument, variables, headers),
      options
    );

useGetBranchesQuery.getKey = (variables?: GetBranchesQueryVariables) => variables === undefined ? ['GetBranches'] : ['GetBranches', variables];
;

export const useInfiniteGetBranchesQuery = <
      TData = GetBranchesQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetBranchesQueryVariables,
      client: GraphQLClient,
      variables?: GetBranchesQueryVariables,
      options?: UseInfiniteQueryOptions<GetBranchesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetBranchesQuery, TError, TData>(
      variables === undefined ? ['GetBranches.infinite'] : ['GetBranches.infinite', variables],
      (metaData) => fetcher<GetBranchesQuery, GetBranchesQueryVariables>(client, GetBranchesDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetBranchesQuery.getKey = (variables?: GetBranchesQueryVariables) => variables === undefined ? ['GetBranches.infinite'] : ['GetBranches.infinite', variables];
;

useGetBranchesQuery.fetcher = (client: GraphQLClient, variables?: GetBranchesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetBranchesQuery, GetBranchesQueryVariables>(client, GetBranchesDocument, variables, headers);
export const GetCommentsDocument = `
    query GetComments($pagination: PaginationArg, $filters: CommentFiltersInput, $sort: [String]) {
  comments(pagination: $pagination, filters: $filters, sort: $sort) {
    data {
      id
      attributes {
        content
        writer {
          data {
            attributes {
              nickname
              email
            }
          }
        }
        createdAt
      }
    }
    meta {
      pagination {
        total
        page
        pageCount
      }
    }
  }
}
    `;
export const useGetCommentsQuery = <
      TData = GetCommentsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCommentsQueryVariables,
      options?: UseQueryOptions<GetCommentsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetCommentsQuery, TError, TData>(
      variables === undefined ? ['GetComments'] : ['GetComments', variables],
      fetcher<GetCommentsQuery, GetCommentsQueryVariables>(client, GetCommentsDocument, variables, headers),
      options
    );

useGetCommentsQuery.getKey = (variables?: GetCommentsQueryVariables) => variables === undefined ? ['GetComments'] : ['GetComments', variables];
;

export const useInfiniteGetCommentsQuery = <
      TData = GetCommentsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetCommentsQueryVariables,
      client: GraphQLClient,
      variables?: GetCommentsQueryVariables,
      options?: UseInfiniteQueryOptions<GetCommentsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetCommentsQuery, TError, TData>(
      variables === undefined ? ['GetComments.infinite'] : ['GetComments.infinite', variables],
      (metaData) => fetcher<GetCommentsQuery, GetCommentsQueryVariables>(client, GetCommentsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetCommentsQuery.getKey = (variables?: GetCommentsQueryVariables) => variables === undefined ? ['GetComments.infinite'] : ['GetComments.infinite', variables];
;

useGetCommentsQuery.fetcher = (client: GraphQLClient, variables?: GetCommentsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetCommentsQuery, GetCommentsQueryVariables>(client, GetCommentsDocument, variables, headers);
export const GetCompanyWhitelistDocument = `
    query GetCompanyWhitelist {
  whitelist {
    data {
      name
      domain
    }
  }
}
    `;
export const useGetCompanyWhitelistQuery = <
      TData = GetCompanyWhitelistQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCompanyWhitelistQueryVariables,
      options?: UseQueryOptions<GetCompanyWhitelistQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetCompanyWhitelistQuery, TError, TData>(
      variables === undefined ? ['GetCompanyWhitelist'] : ['GetCompanyWhitelist', variables],
      fetcher<GetCompanyWhitelistQuery, GetCompanyWhitelistQueryVariables>(client, GetCompanyWhitelistDocument, variables, headers),
      options
    );

useGetCompanyWhitelistQuery.getKey = (variables?: GetCompanyWhitelistQueryVariables) => variables === undefined ? ['GetCompanyWhitelist'] : ['GetCompanyWhitelist', variables];
;

export const useInfiniteGetCompanyWhitelistQuery = <
      TData = GetCompanyWhitelistQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetCompanyWhitelistQueryVariables,
      client: GraphQLClient,
      variables?: GetCompanyWhitelistQueryVariables,
      options?: UseInfiniteQueryOptions<GetCompanyWhitelistQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetCompanyWhitelistQuery, TError, TData>(
      variables === undefined ? ['GetCompanyWhitelist.infinite'] : ['GetCompanyWhitelist.infinite', variables],
      (metaData) => fetcher<GetCompanyWhitelistQuery, GetCompanyWhitelistQueryVariables>(client, GetCompanyWhitelistDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetCompanyWhitelistQuery.getKey = (variables?: GetCompanyWhitelistQueryVariables) => variables === undefined ? ['GetCompanyWhitelist.infinite'] : ['GetCompanyWhitelist.infinite', variables];
;

useGetCompanyWhitelistQuery.fetcher = (client: GraphQLClient, variables?: GetCompanyWhitelistQueryVariables, headers?: RequestInit['headers']) => fetcher<GetCompanyWhitelistQuery, GetCompanyWhitelistQueryVariables>(client, GetCompanyWhitelistDocument, variables, headers);
export const GetCouponDocument = `
    query GetCoupon($id: ID) {
  coupon(id: $id) {
    data {
      attributes {
        state
        couponTemplate {
          data {
            attributes {
              name
              availableCategories {
                data {
                  id
                  attributes {
                    name
                    branch {
                      data {
                        attributes {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        stamp {
          data {
            id
          }
        }
        createdAt
      }
    }
  }
}
    `;
export const useGetCouponQuery = <
      TData = GetCouponQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCouponQueryVariables,
      options?: UseQueryOptions<GetCouponQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetCouponQuery, TError, TData>(
      variables === undefined ? ['GetCoupon'] : ['GetCoupon', variables],
      fetcher<GetCouponQuery, GetCouponQueryVariables>(client, GetCouponDocument, variables, headers),
      options
    );

useGetCouponQuery.getKey = (variables?: GetCouponQueryVariables) => variables === undefined ? ['GetCoupon'] : ['GetCoupon', variables];
;

export const useInfiniteGetCouponQuery = <
      TData = GetCouponQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetCouponQueryVariables,
      client: GraphQLClient,
      variables?: GetCouponQueryVariables,
      options?: UseInfiniteQueryOptions<GetCouponQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetCouponQuery, TError, TData>(
      variables === undefined ? ['GetCoupon.infinite'] : ['GetCoupon.infinite', variables],
      (metaData) => fetcher<GetCouponQuery, GetCouponQueryVariables>(client, GetCouponDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetCouponQuery.getKey = (variables?: GetCouponQueryVariables) => variables === undefined ? ['GetCoupon.infinite'] : ['GetCoupon.infinite', variables];
;

useGetCouponQuery.fetcher = (client: GraphQLClient, variables?: GetCouponQueryVariables, headers?: RequestInit['headers']) => fetcher<GetCouponQuery, GetCouponQueryVariables>(client, GetCouponDocument, variables, headers);
export const GetCouponsDocument = `
    query GetCoupons($filters: CouponFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  coupons(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        couponTemplate {
          data {
            id
            attributes {
              name
              availableCategories {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
        creator
        usedAt
        state
        createdAt
        updatedAt
      }
    }
  }
}
    `;
export const useGetCouponsQuery = <
      TData = GetCouponsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCouponsQueryVariables,
      options?: UseQueryOptions<GetCouponsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetCouponsQuery, TError, TData>(
      variables === undefined ? ['GetCoupons'] : ['GetCoupons', variables],
      fetcher<GetCouponsQuery, GetCouponsQueryVariables>(client, GetCouponsDocument, variables, headers),
      options
    );

useGetCouponsQuery.getKey = (variables?: GetCouponsQueryVariables) => variables === undefined ? ['GetCoupons'] : ['GetCoupons', variables];
;

export const useInfiniteGetCouponsQuery = <
      TData = GetCouponsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetCouponsQueryVariables,
      client: GraphQLClient,
      variables?: GetCouponsQueryVariables,
      options?: UseInfiniteQueryOptions<GetCouponsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetCouponsQuery, TError, TData>(
      variables === undefined ? ['GetCoupons.infinite'] : ['GetCoupons.infinite', variables],
      (metaData) => fetcher<GetCouponsQuery, GetCouponsQueryVariables>(client, GetCouponsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetCouponsQuery.getKey = (variables?: GetCouponsQueryVariables) => variables === undefined ? ['GetCoupons.infinite'] : ['GetCoupons.infinite', variables];
;

useGetCouponsQuery.fetcher = (client: GraphQLClient, variables?: GetCouponsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetCouponsQuery, GetCouponsQueryVariables>(client, GetCouponsDocument, variables, headers);
export const GetCouponsWithPaginationDocument = `
    query GetCouponsWithPagination($pagination: PaginationArg, $filters: CouponFiltersInput, $sort: [String]) {
  coupons(pagination: $pagination, filters: $filters, sort: $sort) {
    data {
      id
      attributes {
        creator
        state
        createdAt
        stamp {
          data {
            id
          }
        }
        couponTemplate {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
        page
        pageCount
      }
    }
  }
}
    `;
export const useGetCouponsWithPaginationQuery = <
      TData = GetCouponsWithPaginationQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCouponsWithPaginationQueryVariables,
      options?: UseQueryOptions<GetCouponsWithPaginationQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetCouponsWithPaginationQuery, TError, TData>(
      variables === undefined ? ['GetCouponsWithPagination'] : ['GetCouponsWithPagination', variables],
      fetcher<GetCouponsWithPaginationQuery, GetCouponsWithPaginationQueryVariables>(client, GetCouponsWithPaginationDocument, variables, headers),
      options
    );

useGetCouponsWithPaginationQuery.getKey = (variables?: GetCouponsWithPaginationQueryVariables) => variables === undefined ? ['GetCouponsWithPagination'] : ['GetCouponsWithPagination', variables];
;

export const useInfiniteGetCouponsWithPaginationQuery = <
      TData = GetCouponsWithPaginationQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetCouponsWithPaginationQueryVariables,
      client: GraphQLClient,
      variables?: GetCouponsWithPaginationQueryVariables,
      options?: UseInfiniteQueryOptions<GetCouponsWithPaginationQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetCouponsWithPaginationQuery, TError, TData>(
      variables === undefined ? ['GetCouponsWithPagination.infinite'] : ['GetCouponsWithPagination.infinite', variables],
      (metaData) => fetcher<GetCouponsWithPaginationQuery, GetCouponsWithPaginationQueryVariables>(client, GetCouponsWithPaginationDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetCouponsWithPaginationQuery.getKey = (variables?: GetCouponsWithPaginationQueryVariables) => variables === undefined ? ['GetCouponsWithPagination.infinite'] : ['GetCouponsWithPagination.infinite', variables];
;

useGetCouponsWithPaginationQuery.fetcher = (client: GraphQLClient, variables?: GetCouponsWithPaginationQueryVariables, headers?: RequestInit['headers']) => fetcher<GetCouponsWithPaginationQuery, GetCouponsWithPaginationQueryVariables>(client, GetCouponsWithPaginationDocument, variables, headers);
export const GetFavoritesDocument = `
    query GetFavorites($filters: FavoriteFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  favorites(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        product {
          data {
            id
            attributes {
              description
              name
              price
              state
              inventory
              isInfiniteInventory
              category {
                data {
                  id
                  attributes {
                    thumbnail {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
              thumbnail {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
    `;
export const useGetFavoritesQuery = <
      TData = GetFavoritesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetFavoritesQueryVariables,
      options?: UseQueryOptions<GetFavoritesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetFavoritesQuery, TError, TData>(
      variables === undefined ? ['GetFavorites'] : ['GetFavorites', variables],
      fetcher<GetFavoritesQuery, GetFavoritesQueryVariables>(client, GetFavoritesDocument, variables, headers),
      options
    );

useGetFavoritesQuery.getKey = (variables?: GetFavoritesQueryVariables) => variables === undefined ? ['GetFavorites'] : ['GetFavorites', variables];
;

export const useInfiniteGetFavoritesQuery = <
      TData = GetFavoritesQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetFavoritesQueryVariables,
      client: GraphQLClient,
      variables?: GetFavoritesQueryVariables,
      options?: UseInfiniteQueryOptions<GetFavoritesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetFavoritesQuery, TError, TData>(
      variables === undefined ? ['GetFavorites.infinite'] : ['GetFavorites.infinite', variables],
      (metaData) => fetcher<GetFavoritesQuery, GetFavoritesQueryVariables>(client, GetFavoritesDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetFavoritesQuery.getKey = (variables?: GetFavoritesQueryVariables) => variables === undefined ? ['GetFavorites.infinite'] : ['GetFavorites.infinite', variables];
;

useGetFavoritesQuery.fetcher = (client: GraphQLClient, variables?: GetFavoritesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetFavoritesQuery, GetFavoritesQueryVariables>(client, GetFavoritesDocument, variables, headers);
export const GetGiftDocument = `
    query GetGift($id: ID) {
  gift(id: $id) {
    data {
      id
      attributes {
        message
        price
        sender {
          data {
            attributes {
              nickname
              email
            }
          }
        }
        price
        name
        state
        createdAt
        giftCard {
          data {
            attributes {
              image {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetGiftQuery = <
      TData = GetGiftQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetGiftQueryVariables,
      options?: UseQueryOptions<GetGiftQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetGiftQuery, TError, TData>(
      variables === undefined ? ['GetGift'] : ['GetGift', variables],
      fetcher<GetGiftQuery, GetGiftQueryVariables>(client, GetGiftDocument, variables, headers),
      options
    );

useGetGiftQuery.getKey = (variables?: GetGiftQueryVariables) => variables === undefined ? ['GetGift'] : ['GetGift', variables];
;

export const useInfiniteGetGiftQuery = <
      TData = GetGiftQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetGiftQueryVariables,
      client: GraphQLClient,
      variables?: GetGiftQueryVariables,
      options?: UseInfiniteQueryOptions<GetGiftQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetGiftQuery, TError, TData>(
      variables === undefined ? ['GetGift.infinite'] : ['GetGift.infinite', variables],
      (metaData) => fetcher<GetGiftQuery, GetGiftQueryVariables>(client, GetGiftDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetGiftQuery.getKey = (variables?: GetGiftQueryVariables) => variables === undefined ? ['GetGift.infinite'] : ['GetGift.infinite', variables];
;

useGetGiftQuery.fetcher = (client: GraphQLClient, variables?: GetGiftQueryVariables, headers?: RequestInit['headers']) => fetcher<GetGiftQuery, GetGiftQueryVariables>(client, GetGiftDocument, variables, headers);
export const GetGiftCardsDocument = `
    query GetGiftCards($filters: GiftCardFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  giftCards(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        image {
          data {
            id
            attributes {
              url
            }
          }
        }
        type
      }
    }
  }
}
    `;
export const useGetGiftCardsQuery = <
      TData = GetGiftCardsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetGiftCardsQueryVariables,
      options?: UseQueryOptions<GetGiftCardsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetGiftCardsQuery, TError, TData>(
      variables === undefined ? ['GetGiftCards'] : ['GetGiftCards', variables],
      fetcher<GetGiftCardsQuery, GetGiftCardsQueryVariables>(client, GetGiftCardsDocument, variables, headers),
      options
    );

useGetGiftCardsQuery.getKey = (variables?: GetGiftCardsQueryVariables) => variables === undefined ? ['GetGiftCards'] : ['GetGiftCards', variables];
;

export const useInfiniteGetGiftCardsQuery = <
      TData = GetGiftCardsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetGiftCardsQueryVariables,
      client: GraphQLClient,
      variables?: GetGiftCardsQueryVariables,
      options?: UseInfiniteQueryOptions<GetGiftCardsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetGiftCardsQuery, TError, TData>(
      variables === undefined ? ['GetGiftCards.infinite'] : ['GetGiftCards.infinite', variables],
      (metaData) => fetcher<GetGiftCardsQuery, GetGiftCardsQueryVariables>(client, GetGiftCardsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetGiftCardsQuery.getKey = (variables?: GetGiftCardsQueryVariables) => variables === undefined ? ['GetGiftCards.infinite'] : ['GetGiftCards.infinite', variables];
;

useGetGiftCardsQuery.fetcher = (client: GraphQLClient, variables?: GetGiftCardsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetGiftCardsQuery, GetGiftCardsQueryVariables>(client, GetGiftCardsDocument, variables, headers);
export const GetGiftsDocument = `
    query GetGifts($filters: GiftFiltersInput) {
  gifts(filters: $filters) {
    data {
      id
      attributes {
        name
        price
        state
        originBranch {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export const useGetGiftsQuery = <
      TData = GetGiftsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetGiftsQueryVariables,
      options?: UseQueryOptions<GetGiftsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetGiftsQuery, TError, TData>(
      variables === undefined ? ['GetGifts'] : ['GetGifts', variables],
      fetcher<GetGiftsQuery, GetGiftsQueryVariables>(client, GetGiftsDocument, variables, headers),
      options
    );

useGetGiftsQuery.getKey = (variables?: GetGiftsQueryVariables) => variables === undefined ? ['GetGifts'] : ['GetGifts', variables];
;

export const useInfiniteGetGiftsQuery = <
      TData = GetGiftsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetGiftsQueryVariables,
      client: GraphQLClient,
      variables?: GetGiftsQueryVariables,
      options?: UseInfiniteQueryOptions<GetGiftsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetGiftsQuery, TError, TData>(
      variables === undefined ? ['GetGifts.infinite'] : ['GetGifts.infinite', variables],
      (metaData) => fetcher<GetGiftsQuery, GetGiftsQueryVariables>(client, GetGiftsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetGiftsQuery.getKey = (variables?: GetGiftsQueryVariables) => variables === undefined ? ['GetGifts.infinite'] : ['GetGifts.infinite', variables];
;

useGetGiftsQuery.fetcher = (client: GraphQLClient, variables?: GetGiftsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetGiftsQuery, GetGiftsQueryVariables>(client, GetGiftsDocument, variables, headers);
export const GetGiftsWithPaginationDocument = `
    query GetGiftsWithPagination($pagination: PaginationArg, $filters: GiftFiltersInput, $sort: [String]) {
  gifts(pagination: $pagination, filters: $filters, sort: $sort) {
    data {
      id
      attributes {
        price
        name
        createdAt
        sender {
          data {
            attributes {
              nickname
              email
            }
          }
        }
        state
      }
    }
    meta {
      pagination {
        total
        page
        pageCount
      }
    }
  }
}
    `;
export const useGetGiftsWithPaginationQuery = <
      TData = GetGiftsWithPaginationQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetGiftsWithPaginationQueryVariables,
      options?: UseQueryOptions<GetGiftsWithPaginationQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetGiftsWithPaginationQuery, TError, TData>(
      variables === undefined ? ['GetGiftsWithPagination'] : ['GetGiftsWithPagination', variables],
      fetcher<GetGiftsWithPaginationQuery, GetGiftsWithPaginationQueryVariables>(client, GetGiftsWithPaginationDocument, variables, headers),
      options
    );

useGetGiftsWithPaginationQuery.getKey = (variables?: GetGiftsWithPaginationQueryVariables) => variables === undefined ? ['GetGiftsWithPagination'] : ['GetGiftsWithPagination', variables];
;

export const useInfiniteGetGiftsWithPaginationQuery = <
      TData = GetGiftsWithPaginationQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetGiftsWithPaginationQueryVariables,
      client: GraphQLClient,
      variables?: GetGiftsWithPaginationQueryVariables,
      options?: UseInfiniteQueryOptions<GetGiftsWithPaginationQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetGiftsWithPaginationQuery, TError, TData>(
      variables === undefined ? ['GetGiftsWithPagination.infinite'] : ['GetGiftsWithPagination.infinite', variables],
      (metaData) => fetcher<GetGiftsWithPaginationQuery, GetGiftsWithPaginationQueryVariables>(client, GetGiftsWithPaginationDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetGiftsWithPaginationQuery.getKey = (variables?: GetGiftsWithPaginationQueryVariables) => variables === undefined ? ['GetGiftsWithPagination.infinite'] : ['GetGiftsWithPagination.infinite', variables];
;

useGetGiftsWithPaginationQuery.fetcher = (client: GraphQLClient, variables?: GetGiftsWithPaginationQueryVariables, headers?: RequestInit['headers']) => fetcher<GetGiftsWithPaginationQuery, GetGiftsWithPaginationQueryVariables>(client, GetGiftsWithPaginationDocument, variables, headers);
export const GetHomeBoardDocument = `
    query GetHomeBoard($filters: HomeBoardFiltersInput, $sort: [String]) {
  homeBoards(filters: $filters, sort: $sort) {
    data {
      id
      attributes {
        exposedArticle {
          data {
            id
            attributes {
              type
              content
              title
            }
          }
        }
        thumbnail {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetHomeBoardQuery = <
      TData = GetHomeBoardQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetHomeBoardQueryVariables,
      options?: UseQueryOptions<GetHomeBoardQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetHomeBoardQuery, TError, TData>(
      variables === undefined ? ['GetHomeBoard'] : ['GetHomeBoard', variables],
      fetcher<GetHomeBoardQuery, GetHomeBoardQueryVariables>(client, GetHomeBoardDocument, variables, headers),
      options
    );

useGetHomeBoardQuery.getKey = (variables?: GetHomeBoardQueryVariables) => variables === undefined ? ['GetHomeBoard'] : ['GetHomeBoard', variables];
;

export const useInfiniteGetHomeBoardQuery = <
      TData = GetHomeBoardQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetHomeBoardQueryVariables,
      client: GraphQLClient,
      variables?: GetHomeBoardQueryVariables,
      options?: UseInfiniteQueryOptions<GetHomeBoardQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetHomeBoardQuery, TError, TData>(
      variables === undefined ? ['GetHomeBoard.infinite'] : ['GetHomeBoard.infinite', variables],
      (metaData) => fetcher<GetHomeBoardQuery, GetHomeBoardQueryVariables>(client, GetHomeBoardDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetHomeBoardQuery.getKey = (variables?: GetHomeBoardQueryVariables) => variables === undefined ? ['GetHomeBoard.infinite'] : ['GetHomeBoard.infinite', variables];
;

useGetHomeBoardQuery.fetcher = (client: GraphQLClient, variables?: GetHomeBoardQueryVariables, headers?: RequestInit['headers']) => fetcher<GetHomeBoardQuery, GetHomeBoardQueryVariables>(client, GetHomeBoardDocument, variables, headers);
export const GetMeDocument = `
    query GetMe {
  me {
    id
    email
    role {
      id
      type
    }
  }
}
    `;
export const useGetMeQuery = <
      TData = GetMeQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetMeQueryVariables,
      options?: UseQueryOptions<GetMeQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetMeQuery, TError, TData>(
      variables === undefined ? ['GetMe'] : ['GetMe', variables],
      fetcher<GetMeQuery, GetMeQueryVariables>(client, GetMeDocument, variables, headers),
      options
    );

useGetMeQuery.getKey = (variables?: GetMeQueryVariables) => variables === undefined ? ['GetMe'] : ['GetMe', variables];
;

export const useInfiniteGetMeQuery = <
      TData = GetMeQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetMeQueryVariables,
      client: GraphQLClient,
      variables?: GetMeQueryVariables,
      options?: UseInfiniteQueryOptions<GetMeQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetMeQuery, TError, TData>(
      variables === undefined ? ['GetMe.infinite'] : ['GetMe.infinite', variables],
      (metaData) => fetcher<GetMeQuery, GetMeQueryVariables>(client, GetMeDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetMeQuery.getKey = (variables?: GetMeQueryVariables) => variables === undefined ? ['GetMe.infinite'] : ['GetMe.infinite', variables];
;

useGetMeQuery.fetcher = (client: GraphQLClient, variables?: GetMeQueryVariables, headers?: RequestInit['headers']) => fetcher<GetMeQuery, GetMeQueryVariables>(client, GetMeDocument, variables, headers);
export const GetOrdersDocument = `
    query GetOrders($pagination: PaginationArg, $filters: OrderFiltersInput, $sort: [String]) {
  orders(pagination: $pagination, filters: $filters, sort: $sort) {
    data {
      id
      attributes {
        request
        product {
          data {
            id
            attributes {
              name
            }
          }
        }
        productOptions
        amount
        branch {
          data {
            attributes {
              name
            }
          }
        }
        state
        quantity
        gift {
          data {
            attributes {
              receiver {
                data {
                  attributes {
                    nickname
                    email
                  }
                }
              }
            }
          }
        }
        createdAt
      }
    }
    meta {
      pagination {
        total
        page
        pageCount
      }
    }
  }
}
    `;
export const useGetOrdersQuery = <
      TData = GetOrdersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetOrdersQueryVariables,
      options?: UseQueryOptions<GetOrdersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetOrdersQuery, TError, TData>(
      variables === undefined ? ['GetOrders'] : ['GetOrders', variables],
      fetcher<GetOrdersQuery, GetOrdersQueryVariables>(client, GetOrdersDocument, variables, headers),
      options
    );

useGetOrdersQuery.getKey = (variables?: GetOrdersQueryVariables) => variables === undefined ? ['GetOrders'] : ['GetOrders', variables];
;

export const useInfiniteGetOrdersQuery = <
      TData = GetOrdersQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetOrdersQueryVariables,
      client: GraphQLClient,
      variables?: GetOrdersQueryVariables,
      options?: UseInfiniteQueryOptions<GetOrdersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetOrdersQuery, TError, TData>(
      variables === undefined ? ['GetOrders.infinite'] : ['GetOrders.infinite', variables],
      (metaData) => fetcher<GetOrdersQuery, GetOrdersQueryVariables>(client, GetOrdersDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetOrdersQuery.getKey = (variables?: GetOrdersQueryVariables) => variables === undefined ? ['GetOrders.infinite'] : ['GetOrders.infinite', variables];
;

useGetOrdersQuery.fetcher = (client: GraphQLClient, variables?: GetOrdersQueryVariables, headers?: RequestInit['headers']) => fetcher<GetOrdersQuery, GetOrdersQueryVariables>(client, GetOrdersDocument, variables, headers);
export const GetProductDocument = `
    query GetProduct($id: ID, $productOptionSort: [String]) {
  product(id: $id) {
    data {
      id
      isFavorite
      attributes {
        description
        name
        price
        state
        inventory
        isInfiniteInventory
        category {
          data {
            id
            attributes {
              thumbnail {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        thumbnail {
          data {
            id
            attributes {
              url
            }
          }
        }
        optionBundles {
          data {
            id
            attributes {
              name
              type
              options(sort: $productOptionSort) {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              defaultOption {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const useGetProductQuery = <
      TData = GetProductQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetProductQueryVariables,
      options?: UseQueryOptions<GetProductQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetProductQuery, TError, TData>(
      variables === undefined ? ['GetProduct'] : ['GetProduct', variables],
      fetcher<GetProductQuery, GetProductQueryVariables>(client, GetProductDocument, variables, headers),
      options
    );

useGetProductQuery.getKey = (variables?: GetProductQueryVariables) => variables === undefined ? ['GetProduct'] : ['GetProduct', variables];
;

export const useInfiniteGetProductQuery = <
      TData = GetProductQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetProductQueryVariables,
      client: GraphQLClient,
      variables?: GetProductQueryVariables,
      options?: UseInfiniteQueryOptions<GetProductQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetProductQuery, TError, TData>(
      variables === undefined ? ['GetProduct.infinite'] : ['GetProduct.infinite', variables],
      (metaData) => fetcher<GetProductQuery, GetProductQueryVariables>(client, GetProductDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetProductQuery.getKey = (variables?: GetProductQueryVariables) => variables === undefined ? ['GetProduct.infinite'] : ['GetProduct.infinite', variables];
;

useGetProductQuery.fetcher = (client: GraphQLClient, variables?: GetProductQueryVariables, headers?: RequestInit['headers']) => fetcher<GetProductQuery, GetProductQueryVariables>(client, GetProductDocument, variables, headers);
export const GetProductCategoriesDocument = `
    query GetProductCategories($filters: ProductCategoryFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  productCategories(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export const useGetProductCategoriesQuery = <
      TData = GetProductCategoriesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetProductCategoriesQueryVariables,
      options?: UseQueryOptions<GetProductCategoriesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetProductCategoriesQuery, TError, TData>(
      variables === undefined ? ['GetProductCategories'] : ['GetProductCategories', variables],
      fetcher<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(client, GetProductCategoriesDocument, variables, headers),
      options
    );

useGetProductCategoriesQuery.getKey = (variables?: GetProductCategoriesQueryVariables) => variables === undefined ? ['GetProductCategories'] : ['GetProductCategories', variables];
;

export const useInfiniteGetProductCategoriesQuery = <
      TData = GetProductCategoriesQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetProductCategoriesQueryVariables,
      client: GraphQLClient,
      variables?: GetProductCategoriesQueryVariables,
      options?: UseInfiniteQueryOptions<GetProductCategoriesQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetProductCategoriesQuery, TError, TData>(
      variables === undefined ? ['GetProductCategories.infinite'] : ['GetProductCategories.infinite', variables],
      (metaData) => fetcher<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(client, GetProductCategoriesDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetProductCategoriesQuery.getKey = (variables?: GetProductCategoriesQueryVariables) => variables === undefined ? ['GetProductCategories.infinite'] : ['GetProductCategories.infinite', variables];
;

useGetProductCategoriesQuery.fetcher = (client: GraphQLClient, variables?: GetProductCategoriesQueryVariables, headers?: RequestInit['headers']) => fetcher<GetProductCategoriesQuery, GetProductCategoriesQueryVariables>(client, GetProductCategoriesDocument, variables, headers);
export const GetProductsDocument = `
    query GetProducts($filters: ProductFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  products(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        description
        name
        price
        state
        inventory
        isInfiniteInventory
        createdAt
        updatedAt
        category {
          data {
            id
            attributes {
              thumbnail {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
        thumbnail {
          data {
            id
            attributes {
              url
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
    `;
export const useGetProductsQuery = <
      TData = GetProductsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetProductsQueryVariables,
      options?: UseQueryOptions<GetProductsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetProductsQuery, TError, TData>(
      variables === undefined ? ['GetProducts'] : ['GetProducts', variables],
      fetcher<GetProductsQuery, GetProductsQueryVariables>(client, GetProductsDocument, variables, headers),
      options
    );

useGetProductsQuery.getKey = (variables?: GetProductsQueryVariables) => variables === undefined ? ['GetProducts'] : ['GetProducts', variables];
;

export const useInfiniteGetProductsQuery = <
      TData = GetProductsQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetProductsQueryVariables,
      client: GraphQLClient,
      variables?: GetProductsQueryVariables,
      options?: UseInfiniteQueryOptions<GetProductsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetProductsQuery, TError, TData>(
      variables === undefined ? ['GetProducts.infinite'] : ['GetProducts.infinite', variables],
      (metaData) => fetcher<GetProductsQuery, GetProductsQueryVariables>(client, GetProductsDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetProductsQuery.getKey = (variables?: GetProductsQueryVariables) => variables === undefined ? ['GetProducts.infinite'] : ['GetProducts.infinite', variables];
;

useGetProductsQuery.fetcher = (client: GraphQLClient, variables?: GetProductsQueryVariables, headers?: RequestInit['headers']) => fetcher<GetProductsQuery, GetProductsQueryVariables>(client, GetProductsDocument, variables, headers);
export const GetStampDocument = `
    query GetStamp($filters: StampFiltersInput) {
  stamps(filters: $filters) {
    data {
      id
      attributes {
        count
      }
    }
  }
}
    `;
export const useGetStampQuery = <
      TData = GetStampQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetStampQueryVariables,
      options?: UseQueryOptions<GetStampQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetStampQuery, TError, TData>(
      variables === undefined ? ['GetStamp'] : ['GetStamp', variables],
      fetcher<GetStampQuery, GetStampQueryVariables>(client, GetStampDocument, variables, headers),
      options
    );

useGetStampQuery.getKey = (variables?: GetStampQueryVariables) => variables === undefined ? ['GetStamp'] : ['GetStamp', variables];
;

export const useInfiniteGetStampQuery = <
      TData = GetStampQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetStampQueryVariables,
      client: GraphQLClient,
      variables?: GetStampQueryVariables,
      options?: UseInfiniteQueryOptions<GetStampQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetStampQuery, TError, TData>(
      variables === undefined ? ['GetStamp.infinite'] : ['GetStamp.infinite', variables],
      (metaData) => fetcher<GetStampQuery, GetStampQueryVariables>(client, GetStampDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetStampQuery.getKey = (variables?: GetStampQueryVariables) => variables === undefined ? ['GetStamp.infinite'] : ['GetStamp.infinite', variables];
;

useGetStampQuery.fetcher = (client: GraphQLClient, variables?: GetStampQueryVariables, headers?: RequestInit['headers']) => fetcher<GetStampQuery, GetStampQueryVariables>(client, GetStampDocument, variables, headers);
export const GetUserDetailDocument = `
    query GetUserDetail($id: ID) {
  usersPermissionsUser(id: $id) {
    data {
      id
      attributes {
        email
        phoneNumber
        nickname
        company
        birthday
      }
    }
  }
}
    `;
export const useGetUserDetailQuery = <
      TData = GetUserDetailQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetUserDetailQueryVariables,
      options?: UseQueryOptions<GetUserDetailQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetUserDetailQuery, TError, TData>(
      variables === undefined ? ['GetUserDetail'] : ['GetUserDetail', variables],
      fetcher<GetUserDetailQuery, GetUserDetailQueryVariables>(client, GetUserDetailDocument, variables, headers),
      options
    );

useGetUserDetailQuery.getKey = (variables?: GetUserDetailQueryVariables) => variables === undefined ? ['GetUserDetail'] : ['GetUserDetail', variables];
;

export const useInfiniteGetUserDetailQuery = <
      TData = GetUserDetailQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetUserDetailQueryVariables,
      client: GraphQLClient,
      variables?: GetUserDetailQueryVariables,
      options?: UseInfiniteQueryOptions<GetUserDetailQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetUserDetailQuery, TError, TData>(
      variables === undefined ? ['GetUserDetail.infinite'] : ['GetUserDetail.infinite', variables],
      (metaData) => fetcher<GetUserDetailQuery, GetUserDetailQueryVariables>(client, GetUserDetailDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetUserDetailQuery.getKey = (variables?: GetUserDetailQueryVariables) => variables === undefined ? ['GetUserDetail.infinite'] : ['GetUserDetail.infinite', variables];
;

useGetUserDetailQuery.fetcher = (client: GraphQLClient, variables?: GetUserDetailQueryVariables, headers?: RequestInit['headers']) => fetcher<GetUserDetailQuery, GetUserDetailQueryVariables>(client, GetUserDetailDocument, variables, headers);
export const GetUsersDocument = `
    query GetUsers($filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  usersPermissionsUsers(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        pid
        username
        email
        nickname
      }
    }
    meta {
      pagination {
        total
        page
        pageSize
        pageCount
      }
    }
  }
}
    `;
export const useGetUsersQuery = <
      TData = GetUsersQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetUsersQueryVariables,
      options?: UseQueryOptions<GetUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetUsersQuery, TError, TData>(
      variables === undefined ? ['GetUsers'] : ['GetUsers', variables],
      fetcher<GetUsersQuery, GetUsersQueryVariables>(client, GetUsersDocument, variables, headers),
      options
    );

useGetUsersQuery.getKey = (variables?: GetUsersQueryVariables) => variables === undefined ? ['GetUsers'] : ['GetUsers', variables];
;

export const useInfiniteGetUsersQuery = <
      TData = GetUsersQuery,
      TError = unknown
    >(
      pageParamKey: keyof GetUsersQueryVariables,
      client: GraphQLClient,
      variables?: GetUsersQueryVariables,
      options?: UseInfiniteQueryOptions<GetUsersQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useInfiniteQuery<GetUsersQuery, TError, TData>(
      variables === undefined ? ['GetUsers.infinite'] : ['GetUsers.infinite', variables],
      (metaData) => fetcher<GetUsersQuery, GetUsersQueryVariables>(client, GetUsersDocument, {...variables, ...(metaData.pageParam ?? {})}, headers)(),
      options
    );


useInfiniteGetUsersQuery.getKey = (variables?: GetUsersQueryVariables) => variables === undefined ? ['GetUsers.infinite'] : ['GetUsers.infinite', variables];
;

useGetUsersQuery.fetcher = (client: GraphQLClient, variables?: GetUsersQueryVariables, headers?: RequestInit['headers']) => fetcher<GetUsersQuery, GetUsersQueryVariables>(client, GetUsersDocument, variables, headers);
export const MadeOrderDocument = `
    mutation MadeOrder($orderData: MadeOrderInputType, $bulkOrderDatas: BulkMadeOrderInputType) {
  madeOrder(orderData: $orderData, bulkOrderDatas: $bulkOrderDatas) {
    message
    ok
  }
}
    `;
export const useMadeOrderMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<MadeOrderMutation, TError, MadeOrderMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<MadeOrderMutation, TError, MadeOrderMutationVariables, TContext>(
      ['MadeOrder'],
      (variables?: MadeOrderMutationVariables) => fetcher<MadeOrderMutation, MadeOrderMutationVariables>(client, MadeOrderDocument, variables, headers)(),
      options
    );
useMadeOrderMutation.fetcher = (client: GraphQLClient, variables?: MadeOrderMutationVariables, headers?: RequestInit['headers']) => fetcher<MadeOrderMutation, MadeOrderMutationVariables>(client, MadeOrderDocument, variables, headers);
export const UpdateFavoriteDocument = `
    mutation UpdateFavorite($id: ID!, $data: FavoriteInput!) {
  updateFavorite(id: $id, data: $data) {
    data {
      id
    }
  }
}
    `;
export const useUpdateFavoriteMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateFavoriteMutation, TError, UpdateFavoriteMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateFavoriteMutation, TError, UpdateFavoriteMutationVariables, TContext>(
      ['UpdateFavorite'],
      (variables?: UpdateFavoriteMutationVariables) => fetcher<UpdateFavoriteMutation, UpdateFavoriteMutationVariables>(client, UpdateFavoriteDocument, variables, headers)(),
      options
    );
useUpdateFavoriteMutation.fetcher = (client: GraphQLClient, variables: UpdateFavoriteMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateFavoriteMutation, UpdateFavoriteMutationVariables>(client, UpdateFavoriteDocument, variables, headers);
export const UpdateUserDocument = `
    mutation UpdateUser($id: ID!, $data: UsersPermissionsUserInput!) {
  updateUsersPermissionsUser(id: $id, data: $data) {
    data {
      id
      attributes {
        company
        birthday
        phoneNumber
        nickname
      }
    }
  }
}
    `;
export const useUpdateUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateUserMutation, TError, UpdateUserMutationVariables, TContext>(
      ['UpdateUser'],
      (variables?: UpdateUserMutationVariables) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(client, UpdateUserDocument, variables, headers)(),
      options
    );
useUpdateUserMutation.fetcher = (client: GraphQLClient, variables: UpdateUserMutationVariables, headers?: RequestInit['headers']) => fetcher<UpdateUserMutation, UpdateUserMutationVariables>(client, UpdateUserDocument, variables, headers);