import { GraphQLClient } from 'graphql-request';
import { API_BASE_URL } from 'utils/config';
import { getToken } from 'utils/auth';

const requestHeaders = {
  authorization: `Bearer ${getToken()}`,
};

const graphqlRequestClient = new GraphQLClient(`${API_BASE_URL}/graphql`, {
  headers: requestHeaders,
});

export default graphqlRequestClient;
