import React, { useEffect } from 'react';
import Head from 'next/head';
import useWindowSize from 'hooks/useWindowSize';
import styles from './Layout.module.css';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import { useSetAtom } from 'jotai';
import { globalThemeColorAtom } from 'atom/store';
import { GetBranchQuery, useGetBranchQuery } from 'codegen/generated/graphql';
import graphqlRequestClient from 'utils/graphqlRequestClient';

interface LayoutProps {
  title?: string;
  children: React.ReactNode;
}

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

function Layout({ title = '더핑크퐁컴퍼니 카페', children }: LayoutProps) {
  const { height } = useWindowSize();
  const router = useRouter();
  const { branchId } = router.query;
  // ios, android 등 모바일 기기의 100vh 이슈 해결
  useEffect(() => {
    document.documentElement.style.setProperty('--vh', `${height}px`);
  }, [height]);

  const setGlobalThemeColorAtom = useSetAtom(globalThemeColorAtom);

  useGetBranchQuery<GetBranchQuery>(
    graphqlRequestClient,
    {
      id: branchId as string,
    },
    {
      enabled: !!branchId,
      onSuccess: (data) => {
        if (!data.branch?.data.attributes?.color) return;
        setGlobalThemeColorAtom(data.branch.data.attributes.color);
      },
    }
  );

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content="더핑크퐁컴퍼니 카페 웹 서비스" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
        <link
          rel="icon"
          href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>☕️</text></svg>"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/nprogress/0.2.0/nprogress.min.css"
        />
      </Head>
      <main className={styles.main}>{children}</main>
    </>
  );
}

export default Layout;
