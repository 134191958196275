import nookies from 'nookies';
import { GetServerSidePropsContext } from 'next';
import { defaultCookieConfig } from 'utils/config';
import { CookieSerializeOptions } from 'cookie';

const TOKEN_NAME = 'pid_token';

export function getToken(ctx?: GetServerSidePropsContext) {
  const cookies = nookies.get(ctx);
  return cookies[TOKEN_NAME];
}

export function setToken(
  ctx: GetServerSidePropsContext,
  token: string,
  cookieConfig?: CookieSerializeOptions
) {
  nookies.set(ctx, TOKEN_NAME, token, {
    ...defaultCookieConfig,
    ...(cookieConfig && cookieConfig),
  });
}

export function destroyToken(ctx?: GetServerSidePropsContext) {
  nookies.destroy(ctx, TOKEN_NAME, defaultCookieConfig);
}
